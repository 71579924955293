<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <div class="icon-modal">
          <i class="bi bi-exclamation-triangle me-3"></i>
        </div>
        <h3 class="mb-3 text-center">{{ "guestBox.wrongGuid" | translate }}</h3>
        <p class="mb-3">{{ "guestBox.descWrongGuid" | translate }}</p>
      </div>
    </div>
  </div>
</div>

