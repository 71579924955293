import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Order } from 'src/entities/order';
import { ApiService } from '../service/api.service';
import { DeviceService } from '../service/device.service';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent {
  @Input() order!: Order;
  isMobile: boolean;
  grandTotal$: Subject<number>;
  shippingCost$: Subject<number>;
  subtotal$: Subject<number>;
  isAfterOrder: boolean = false;
  constructor(
    private _apiService: ApiService,
    private route: Router,
    private _detectorService: DeviceService,
    private _utilityService: UtilityService
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
    this.grandTotal$ = this._apiService.grandTotal$;
    this.shippingCost$ = this._apiService.shippingCost$;
    this.subtotal$ = this._apiService.subtotal$;
  }

  ngOnInit(): void {
    this.isAfterOrder = this._utilityService.isOrderAfterGoLive(this.order.dbu);
  }

  goToSignUp() {
    this.route.navigate(['/signup']);
  }

  goToSignIn() {
    this._utilityService.exitMenu();
  }
}
