<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <h4 class="mb-4">{{ 'payment.txtTitle' | translate | uppercase}}</h4>
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <div id="payment-modal" class="row pb-sm-0 mb-4">
          <div class="row pb-md-0 mb-md-0 text-center" [ngClass]="isMobile ? 'mb-sm-1 pb-sm-1' : 'pb-sm-4 mb-sm-5'">
            <div class="col-md-12">
              <h4 class="mb-4">{{ 'payment.txtTitle' | translate | uppercase}}</h4>
            </div>
          </div>
          <div class="row pb-md-3 pb-sm-0 mb-3">
            <div class="col-md-6" [ngClass]="{'mb-sm-4': isMobile}" *ngIf="acceptedMethod.indexOf('carta di credito') > -1">
              <img class="mb-3" [src]="assetImg['visa']" alt="visa" />
              <p class="mb-3">{{ "payment.txtVisa" | translate }}</p>
              <p [innerHTML]="'payment.txtCommon' | translate"></p>
            </div>
            <div class="col-md-6" *ngIf="acceptedMethod.indexOf('bonifico') > -1">
              <img class="mb-3" [src]="assetImg['bonifico']" alt="bonifico" />
              <p class="mb-3">{{ "payment.txtBonifico" | translate }}</p>
              <p [innerHTML]="'payment.txtCommon' | translate"></p>
            </div>
          </div>
          <div class="row pb-md-5 pb-sm-0 mb-5">
            <div class="col-md-6" [ngClass]="{'mb-sm-4': isMobile}" *ngIf="acceptedMethod.indexOf('assegno circolare') > -1">
              <img class="mb-3" [src]="assetImg['assegno']" alt="assegno" />
              <p [innerHTML]="'payment.txtAssegno' | translate"></p>
            </div>
            <div class="col-md-6" *ngIf="acceptedMethod.indexOf('pagamento in contanti') > -1">
              <img class="mb-3" [src]="assetImg['consegna']" alt="consegna" />
              <p>{{ "payment.txtConsegna" | translate }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4 offset-md-4">
          <button class="btn btn-primary w-100 mb-4" (click)="close($event)">
            {{ "buttons.closeModal" | translate }}
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>
