import { ApiService } from '../service/api.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserService } from '../service/user.service';
import { UtilityService } from '../service/utility.service';
import { environment } from 'src/environments/environment';
import { exitSection } from 'src/config/menu';
import { headerLinks } from "./headerLinks";
import { headerLinksData } from "./headerLinksData";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() mobileMenuOpen: boolean = false;
  @Input() notValidDomain: boolean = false;
  @Output() toggleMobileMenuEvent: EventEmitter<boolean> = new EventEmitter();
  isGuest: boolean;
  isMobile: boolean;
  iconMobileExit:string =  exitSection.iconM;

  currentLanguage: string = '';
  links!: headerLinks;
  magentoUrl: string = '';
  mondoConv: string = '';
  logoUrl:string = 'header.logo';
  fullname$!: Subject<string>;

  constructor(
    private _detectorService: DeviceService,
    private _router: Router,
    private _userService: UserService,
    private _utilityService: UtilityService,
    public _apiService: ApiService,
  ) {
    this.isGuest = this._apiService.isGuest$.getValue();
    this.isMobile = this._detectorService._isMobile.getValue();
    this.mondoConv = environment.magento.it;
  }

  ngOnInit(): void {
    this.fullname$ = this._userService.fullname$
    const { it: magentoFe } = environment.magento;
    this.magentoUrl = magentoFe;
    this.links = (this.currentLanguage === 'it')? headerLinksData.it : headerLinksData.es;
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
    this.toggleMobileMenuEvent.emit(this.mobileMenuOpen);
  }

  exitMenu() {
    this._utilityService.exitMenu();
  }
}
