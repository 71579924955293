<div id="box_form" class="shadow bg-white">
  <h1 class="text-center mb-md-1 mb-sm-3">{{ "login.loginAccount" | translate }}<br>Mondo Convenienza</h1>
  <form [formGroup]="loginAdminDataForm" class="mb-4">
    <div class="row mb-md-3 mb-sm-3">
      <div class="form-loginAdminDataFormGroup w-100">
        <input class="form-control" id="email" type="text" formControlName="email" placeholder="User ID" />
        <!-- <app-error-form [fieldControl]="loginAdminDataForm.controls['email']" codeError="errors.email"></app-error-form>-->
      </div>
    </div>
    <div class="mc-password-field input-group mx-0">
      <input class="form-control" id="password" [type]="show_button ? 'text' : 'password'" formControlName="password"
        placeholder="Password" />
      <i class="input-group-text" [class]="show_eye ? 'bi bi-eye' : 'bi bi-eye-slash'" (click)="showPassword()"></i>

    </div>
    <div class="mb-md-2 mb-sm-3">
      <app-error-form [fieldControl]="loginAdminDataForm.controls['password']" codeError="errors.passwordReq">
      </app-error-form>
    </div>
    <button type="submit" class="btn btn-primary w-100 mb-0" [disabled]="loginAdminDataForm.invalid" (click)="onSubmit()">
      {{ "buttons.access" | translate }}
    </button>
  </form>
</div>
<app-loader *ngIf="loader"></app-loader>
