<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content" id="modal-login-admin-fail">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <h4>
          {{ "table.modal.txtTitle" | translate }}
        </h4>
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <h3 class="mb-3 text-center">{{ "login.titleAdmin" | translate }}</h3>
        <p class="text-center">{{ "login.descAdmin" | translate }}</p>
      </div>
    </div>
  </div>