import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { GtmService } from '../service/gtm.service';
import { MAIL_OUTLINE } from 'src/config/images';
import { RouteService } from '../service/route.service';
import { SignupService } from '../service/signup.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-account-box',
  templateUrl: './create-account-box.component.html',
  styleUrls: ['./create-account-box.component.scss'],
})
export class CreateAccountBoxComponent {
  createAccountForm!: UntypedFormGroup;
  id: number = 0;
  image_mail_outline = MAIL_OUTLINE
  loader: boolean = false;
  privacyPolicy: string = '';
  redirect: boolean = false;
  redirectUrl: string = '';
  showModal: boolean = false;
  show_button: Array<boolean> = [false, false];
  show_eye: Array<boolean> = [false, false];
  txtEmail: boolean = false;
  txtGenericErr: boolean = false;
  txtPassword: boolean = false;
  txtSuccess: boolean = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _gtmService: GtmService,
    private _routeService: RouteService,
    private _signupService: SignupService,
    public _translate: TranslateService,
    private authService: SocialAuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {

    _translate.get('privacyPolicy').subscribe((val: string) => {
      this.privacyPolicy = val;
    });

    this.initForm();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if ('mode' in params) {
        if (params.mode === 'redirect') {
          this.redirect = true;
          this.redirectUrl = this._routeService.validateQueryParams(params.redirectUrl);
        } else {
          this.router.navigate(['/signup']);
        }
      }
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  onSubmit() {
    this.txtEmail = false;
    this.txtPassword = false;
    this.txtSuccess = false;
    this.txtGenericErr = false;

    if (this.createAccountForm.valid) {
      this.loader=true;
      this._signupService.cbkSignup({
        email: this.createAccountForm.value.email,
        password: this.createAccountForm.value.password,
        firstname: this.createAccountForm.value.firstname,
        lastname: this.createAccountForm.value.lastname,
        marketing: this.createAccountForm.value.marketing === "" ? false : true
      }).subscribe((response: any) => {
        this.loader=false;
        if (response.status == 450) this.txtPassword = true;

        if (response.status == 451) this.txtEmail = true;

        if (response.status == 455) this.txtGenericErr = true;

        if (response.status == 200) {
          const data = (typeof response.data === 'string') ? JSON.parse(response.data) : [];
          this._gtmService.eventSignup({
            email: this.createAccountForm.value.email,
            firstname: this.createAccountForm.value.firstname,
            lastname: this.createAccountForm.value.lastname,
            id: data[0]
          });

          this.createAccountForm.reset();
          this.txtSuccess = true;

          setTimeout(() => {
            window.location.reload();
          },3000);
        }
        this.createAccountForm.reset();
        this.changeShowModal()
      });
    }
  }

  initForm() {
    this.createAccountForm = this._fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@.,;:()+=£|<>$%^&*-]).{8,}$")]],
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
      passwordRepeat: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@.,;:()+=£|<>$%^&*-]).{8,}$")]],
      privacy: ['', Validators.required],
      marketing: [''],
    });
  }

  showPassword(n: number) {
    this.show_button[n] = !this.show_button[n];
    this.show_eye[n] = !this.show_eye[n];
  }
  goToSignIn() {
    if (this.redirect) {
      this.router.navigate(['/login'], {queryParams: {mode: 'redirect', redirectUrl: this.redirectUrl}});
    } else {
      this._routeService.goTo('login');
    }
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }
}
