import { Component, ViewContainerRef } from '@angular/core';
import { Router } from "@angular/router";
import { MoreInfoModalComponent } from 'src/app/modal/more-info/more-info-modal.component';
import { DynamicModalService } from 'src/app/service/modal/dynamicModal.service';

@Component({
  selector: 'app-button-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
})
export class MoreInfoComponent extends DynamicModalService<MoreInfoModalComponent> {

  constructor(
    private router: Router,
    private viewContainerRef: ViewContainerRef,
  ) {
    super();
  }

  openModalMoreInfo(e: Event) {
    e.preventDefault();

    this.setRootViewContainerRef(this.viewContainerRef);

    const componentRef = this.addDynamicComponent(MoreInfoModalComponent);

    componentRef.instance.closeModal.subscribe(() => {
        this.removeDynamicComponent(componentRef);
    });
  }
}

