<div class="container" id="order-detail" *ngIf="order$ | async as order">
  <div class="row mb-5">
    <div class="col-md-6 text-md-start text-sm-center text-sm-left-c">
      <h1 class="border-bottom border-red border-3 pb-2 d-inline-block">
        <i class="bi bi-box2"></i>
        <i class="bi bi-check"></i>
        <span>{{ "orders.detailOrder" | translate }}</span>
      </h1>
    </div>
    <div
      class="col-md-6 text-md-end text-sm-start text-sm-right-c position-sm-absolute"
    >
      <a (click)="cameBack()" class="btn-link">
        <i
          class="fa fa-arrow-left d-md-none text-red fz-25"
          aria-hidden="true"
        ></i>
        <span class="d-md-block d-sm-none">
          < {{ "common.cameBack" | translate }}</span
        >
      </a>
    </div>
  </div>
  <div class="row" [ngClass]="isMobile ? 'mb-3' : 'mb-4 border-bottom'">
    <div class="col-md-6">
      <div class="row mb-md-4 mb-sm-1" *ngIf="order.guid">
        <div [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtGuid" | translate | uppercase }}
          </p>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'">
          <p>{{ order.guid }}</p>
        </div>
      </div>
      <div class="row mb-md-4 mb-sm-1">
        <div [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtOrder" | translate | uppercase }}
          </p>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'">
          <p *ngIf="!isParteOrdine; else isParteOrdineT">{{ order.prbu }}{{ order.rev }}</p>
          <ng-template #isParteOrdineT>
            <p (click)="openModalServices($event, typeModalService)" style="cursor: pointer;">{{ order.prbu }}{{ order.rev }}</p>
          </ng-template>
          <p
            *ngIf="isParteOrdine"
            class="par_2 mb-md-0 mb-sm-2"
            style="text-decoration: underline"
          >
            {{ order.prbu }}0
          </p>
          <app-container-servizi-mc [data]="order"></app-container-servizi-mc>
        </div>
      </div>
      <!-- <div class="row mb-md-4 mb-sm-1" *ngIf="order.rev != '0'">
        <div class="col-4">
          <p class="fw-semibold">{{ 'orders.txtRevision' | translate | uppercase}}</p>
        </div>
        <div class="col-8">
          <p>{{ order.rev}}</p>
        </div>
      </div> -->
      <div class="row mb-md-4 mb-sm-1">
        <div [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtPurchaseMode" | translate | uppercase }}
          </p>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'">
          <p>{{ order.canale }}</p>
        </div>
      </div>
      <div class="row mb-md-4 mb-sm-1">
        <div [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtDate" | translate | uppercase }}
          </p>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'">
          <p>{{ order.dbu | date : "dd-MM-yyyy" }}</p>
        </div>
      </div>
      <div class="row mb-md-4 mb-sm-1">
        <div [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtTotal" | translate | uppercase }}
          </p>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'">
          <p class="price">
            <span class="original-price" [ngClass]="order.impgeo > 0 ? 'strikethrough' : ''">
              {{ order.totale | currency: "EUR" }}
            </span>
            <span *ngIf="order.impgeo > 0" class="new-price">
              {{ order.totale - order.impgeo | currency: "EUR" }}
            </span>
          </p>
        </div>
      </div>
      <div class="row mb-md-4 mb-sm-1" *ngIf="order.saldo > 0">
        <div class="inlineFlex" [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtDaSaldare" | translate | uppercase }}
          </p>
          <div *ngIf="order.statoKey !== states[6] && !order.annullato">
            <i
              *ngIf="order.saldo > 0"
              class="bi bi-info-circle-fill px-1"
              [ngClass]="isMobile ? 'icon-sm' : 'icon-lg'"
              (click)="openModal($event, order, order.saldo)"
            ></i>
          </div>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'" *ngIf="order.saldo <= 0">
          <p>{{ 0 | currency : "EUR" }}</p>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'" *ngIf="order.saldo > 0">
          <div
            *ngIf="!order.annullato">
            <p>{{ order.saldo | currency : "EUR" }}</p>
          </div>
        </div>
      </div>
      <div class="row mb-md-4 mb-sm-1">
        <div class="inlineFlex" [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtMethod" | translate | uppercase }}
          </p>
          <i
            class="bi bi-info-circle-fill px-1"
            [ngClass]="isMobile ? 'icon-sm' : 'icon-lg'"
            (click)="openModalShipping($event)"
          ></i>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'">
          <p>{{ order.consegna }}</p>
        </div>
      </div>
      <div class="row mb-md-4 mb-sm-1">
        <div [ngClass]="isMobile ? 'col-5' : 'col-4'">
          <p class="fw-semibold">
            {{ "orders.txtState" | translate | uppercase }}
          </p>
        </div>
        <div [ngClass]="isMobile ? 'col-7' : 'col-8'">
          <p class="inlineFlex">{{ order.stato }}</p>
          <i
            *ngIf="isCambioConRitiro"
            class="bi bi-info-circle-fill px-1"
            [ngClass]="isMobile ? 'icon-sm' : 'icon-lg'"
            (click)="openModalServices($event, 'cambioRitiro')"
          ></i>
          <i
            *ngIf="order.statoKey === states[1]"
            class="bi bi-info-circle-fill px-1"
            [ngClass]="isMobile ? 'icon-sm' : 'icon-lg'"
            (click)="openModalServices($event, 'geometra')"
          ></i>
        </div>
      </div>
    </div>
    <div class="col-md-6 text-end" *ngIf="!isMobile">
      <app-orders-actions
        [data]="order"
        [isDetail]="true"
        [invoice]="urlsInvoice$ | async"
      >
      </app-orders-actions>
      <app-assistance
        [showButtonActions]="true"
        [isCardProduct]="false"
        [order]="order">
      </app-assistance>
    </div>
  </div>
  <app-order-detail-address
    [order]="order"
    [isMobile]="isMobile"
    [urlsWarehouseMaps]="urlsWarehouseMaps"></app-order-detail-address>
  <div *ngIf="isMobile">
    <div
      class="col-md-6 text-end"
      class="border-bottom"
      style="margin-bottom: 1rem"
    >
      <app-orders-actions
      [data]="order"
      [isDetail]="true"
      [invoice]="urlsInvoice$ | async"
      >
      </app-orders-actions>
      <app-assistance
        [showButtonActions]="true"
        [isCardProduct]="false"
        [order]="order">
      </app-assistance>
    </div>
  </div>
  <div class="row pt-4">
    <app-order-table
      *ngIf="!isLogged && isNumeric(order.rev)"
      [order]="order"
    ></app-order-table>
    <div class="row pt-4">
      <div
        class="col-12 text-md-end border-bottom no-border-mobile my-4 pb-5"
        *ngIf="!isLogged && isNumeric(order.rev)"
      >
        <div class="row mb-md-2 mb-sm-1">
          <div class="col-md-10 col-sm-6">
            <p class="par_2">
              {{ "cardProduct.txtSubtotal" | translate | titlecase }}
            </p>
          </div>
          <div class="col-md-2 col-sm-6 text-sm-end">
            <p class="par_2">{{ subtotal$ | async | currency : "EUR" }}</p>
          </div>
        </div>
        <div class="row mb-md-2 mb-sm-1">
          <div class="col-md-10 col-sm-6">
            <p class="par_2">
              {{ "cardProduct.txtShipping" | translate | titlecase }}
            </p>
          </div>
          <div class="col-md-2 col-sm-6 text-sm-end">
            <p class="par_2">{{ shippingCost$ | async | currency : "EUR" }}</p>
          </div>
        </div>
        <div class="row mb-md-2 mb-sm-1" *ngIf="order.impgeo > 0">
          <div class="col-md-10 col-sm-6">
            <p class="fw-bold">
              {{ "cardProduct.txtDiscount" | translate | titlecase }}
            </p>
          </div>
          <div class="col-md-2 col-sm-6 text-sm-end">
            <p class="fw-bold">{{ order.impgeo | currency : "EUR" }}</p>
          </div>
        </div>
        <div class="row mb-md-2 mb-sm-1">
          <div class="col-md-10 col-sm-6">
            <p class="fw-bold">
              {{ "cardProduct.txtTotal" | translate | titlecase }}
            </p>
          </div>
          <div class="col-md-2 col-sm-6 text-sm-end">
            <p class="fw-bold">{{ grandTotal$ | async | currency : "EUR" }}</p>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!!isLogged && isNumeric(order.rev)">
        <app-card-product
          [products]="order.dettagli"
          [order]="order">
        </app-card-product>
      </ng-container>
      <app-help-section *ngIf="!isLogged"></app-help-section>
    </div>
  </div>
</div>
