<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content" id="modal-trasporto-montaggio">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <h4>
          {{ "table.modal.txtTitle" | translate }}
        </h4>
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <div id="funzione-disabilitata-modal" class="row pb-sm-0">
          <h4 *ngIf="!isMobile" class="mb-3 text-center">{{ "table.modal.txtTitle" | translate }}</h4>
          <p class="mb-4 text-center">{{ "table.modal.txtDes" | translate }}</p>
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-blue-reverse w-100" [ngClass]="{'btn-mobile': isMobile}"
                (click)=" goToSignIn()">
                {{ "buttons.access" | translate | uppercase}}
              </button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-primary w-100" [ngClass]="{'btn-mobile': isMobile}" (click)=" goToSignUp()">
                {{ "buttons.signUp" | translate | uppercase}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>