import { CONTROL_CODE } from 'src/config/images';
import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceService } from '../../service/device.service';

@Component({
  selector: 'app-code-control',
  templateUrl: './code-control.component.html',
  styleUrls: ['./code-control.component.scss'],
})
export class CodeControlComponent {
  @Output() closeModal = new EventEmitter<any>();

  image_control_code = CONTROL_CODE;
  isMobile: boolean = false;
  
  constructor(
    private _deviceService: DeviceService,
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }

  close(event: any) {
    this.closeModal.emit(event);
  }
}

