import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { PayByLinkAdminComponent } from "../../modal/pay-by-link-admin/pay-by-link-admin.component";
import { PblInfo } from "src/entities/PblInfo";

@Injectable({
  providedIn: 'root',
})
export class PayByLinkAdminService {
  private rootViewContainer!: ViewContainerRef;

  constructor() {}

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }
  
  addDynamicComponent(payByLinkInfoModal:PblInfo, ) {
      const component:ComponentRef<PayByLinkAdminComponent> = this.rootViewContainer.createComponent(PayByLinkAdminComponent);
      component.instance.payByLinkInfoModal = payByLinkInfoModal;
      component.instance.closeModalAdmin.subscribe(() => this.removeDynamicComponent(component));

      this.rootViewContainer.insert(component.hostView);
  }

  removeDynamicComponent(component: ComponentRef<PayByLinkAdminComponent>) {
      component.destroy();
  }
}
