import { Component } from '@angular/core';
import { UserService } from '../service/user.service';
import { Observable } from 'rxjs';
import { ReservationService } from '../service/reservation.service';
import { RESERVATION } from 'src/config/images';

@Component({
  selector: 'app-banner-reservation',
  templateUrl: './banner-reservation.component.html',
  styleUrls: ['./banner-reservation.component.scss'],
})
export class BannerReservationComponent {
  isBannerHidden:boolean = false;
  assetImgMail = RESERVATION['mail'];

  orders$!: Observable<string[]>; 
  fullname: any = '';

  constructor(
    private _userService: UserService,
    private _reservationService: ReservationService,
  ) {
    this.fullname = this._userService.fullname$;
    this.orders$ = this._reservationService.reservation$;
  }

  hideBanner() {
    this.isBannerHidden = true;
  }
}
