import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  private _hasFinished = new BehaviorSubject<boolean>(false);
  public readonly hasFinished$ = this._hasFinished.asObservable();

  constructor() {}

  setHasFinished(v: boolean) {
    this._hasFinished.next(v);
  }

  getHasFinished() {
    return this._hasFinished.getValue()
  }

  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }
}
