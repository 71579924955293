<div class="container" id="newsletter">
  <div class="row mb-5">
    <div class="col-md-6 text-md-start text-sm-center text-sm-left-c">
      <h1>
        <i class="fa fa-envelope-o" aria-hidden="true"></i>
        <span class="border-bottom border-red border-3 pb-2 d-inline-block">{{ "menu.newsletter" | translate }}</span>
      </h1>
    </div>
    <div class="col-md-6 text-md-end text-sm-start text-sm-right-c position-sm-absolute d-sm-block">
      <a (click)="cameBack()" class="btn-link">
        <i class="fa fa-arrow-left d-md-none text-red fz-25" aria-hidden="true"></i>
        <span class="d-md-block d-sm-none">
          < {{ "common.cameBack" | translate }}</span>
      </a>
    </div>
  </div>
  <form [formGroup]="newsletterDataForm">
    <div class="row">
      <div class="col-md-6 order-md-1 order-sm-2">
        <div *ngIf="isSubscribe$ | async; else notSubscribe">
          <h2 class="fz-40 text-red mb-4">
            {{ "newsletter.description2" | translate }}
          </h2>
          <div class="d-flex flex-row align-items-start mb-4">
            <input mdbCheckbox type="checkbox" id="flexCheckDefault" name="flexCheckDefault" formControlName="flexCheckDefault" [checked]="isSubscribe$ | async"/>
            <label class="form-check-label" for="flexCheckDefault">
              <span class="par_2">{{ "newsletter.cancel" | translate}}</span>
            </label>
          </div>
          <div class="row m-0">
            <button
              type="button"
              name="btnSubscribe"
              id="btnSubscribe2"
              class="btn btn-primary"
              (click)="unsubscribe()">
                {{ "buttons.save" | translate }}
            </button>
          </div>
        </div>
        <ng-template #notSubscribe>
          <h2 class="fz-40 text-red mb-3">
            {{ "newsletter.text" | translate }}
          </h2>
          <h2 class="font-family-montserrat mb-3 fs-sm-18">
            {{ "newsletter.description" | translate }}
          </h2>
          <div class="d-flex flex-row align-items-start">
            <input mdbCheckbox type="checkbox" id="flexCheckDefault" name="flexCheckDefault" formControlName="flexCheckDefault"/>
            <label class="form-check-label" for="flexCheckDefault">
              <span class="par_2" [innerHTML]="'newsletter.txtPrivacy' | translate"></span>
            </label>
          </div>
          <div class="row m-0">
            <div class="col-md-7 col-sm-8 p-0">
              <div class="form-check p-0">
                <input class="form-control" name="newsletterMail" id="newsletterMail" type="text"
                  formControlName="newsletterMail"
                  placeholder="Inserisci qui la tua mail">
              </div>
            </div>
            <div class="col-md-5 col-sm-4 p-0">
              <div class="form-check p-0">
                <button
                  type="button"
                  name="btnSubscribe"
                  id="btnSubscribe"
                  class="btn btn-primary w-100"
                  (click)="subscribe()">
                    {{ "buttons.subscribe" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="col-md-6 order-md-2 order-sm-1 mb-md-0 mb-sm-4">
        <img [src]="image" alt="img-newsletter" class="w-100">
      </div>
    </div>
  </form>
</div>
<app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
  <ng-container *ngIf="typePopup === 'subscribe'">
    <div class="Modal-container confirmAppointment">
      <i class="bi bi-check-lg d-flex justify-content-center"></i>
      <p class="confirm mb-3">{{ "newsletter.subscribe" | translate }}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="typePopup === 'unsubscribe'">
    <div class="Modal-container confirmAppointment">
      <i class="bi bi-check-lg d-flex justify-content-center"></i>
      <p class="confirm mb-3">{{ "newsletter.unsubscribe" | translate }}</p>
    </div>
  </ng-container>
</app-custom-modal>
