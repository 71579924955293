<div class="container" id="personalData">
  <div class="row mb-5">
    <div class="col-md-6 text-md-start text-sm-center text-sm-left-c">
      <h1>
        <i class="bi bi-person"></i>
        <span class="border-bottom border-red border-3 pb-2 d-inline-block">{{ "menu.personalData" | translate }}</span>
      </h1>
    </div>
    <div class="col-md-6 text-md-end text-sm-start text-sm-right-c position-sm-absolute d-sm-block">
      <a (click)="cameBack();" class="btn-link">
        <i class="fa fa-arrow-left d-md-none text-red fz-25" aria-hidden="true"></i>
        <span class="d-md-block d-sm-none">
          < {{ "common.cameBack" | translate }}</span>
      </a>
    </div>
  </div>

  <form [formGroup]="personalDataForm">
    <div class="row mb-3">
      <div class="form-gpersonalDataFormroup col-md-6">
        <label for="firstname">
          <p class="par_2">{{ "commons.txtFirstname" | translate }}*</p>
        </label>
        <input class="form-control" id="firstname" type="text" formControlName="firstName" [readOnly]="!clickEditPersonalData"/>
        <app-error-form [fieldControl]="personalDataForm.controls['firstName']" codeError="errors.firstname">
        </app-error-form>
      </div>

      <div class="form-group col-md-6">
        <label for="lastname">
          <p class="par_2">{{ "commons.txtLastname" | translate }}*</p>
        </label>
        <input class="form-control" id="lastname" type="text" formControlName="lastName" [readOnly]="!clickEditPersonalData"/>
        <app-error-form [fieldControl]="personalDataForm.controls['lastName']" codeError="errors.lastname">
        </app-error-form>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="email">
        <p class="par_2">{{ "personalData.txtEmail" | translate }}*</p>
      </label>
      <input 
        class="form-control" 
        id="email" 
        type="email" 
        formControlName="email" 
        readonly="true" />
      <!-- <app-error-form [fieldControl]="personalDataForm.controls['email']"
          codeError="errors.email"></app-error-form> -->
    </div>
    <!-- <div class="form-group">
      <label for="pwd">
        <p class="par_2">{{ "personalData.txtPwd" | translate }}</p>
      </label>
      <div class="mc-password-field input-group mb-3">
        <input [type]="show_button[0] ? 'text' : 'password'" class="form-control" formControlName="pwd" id="pwd" />
        <i class="input-group-text" [class]="show_eye[0] ? 'bi bi-eye' : 'bi bi-eye-slash'"
          (click)="showPassword(0)"></i>
      </div>
    </div>
    <div class="form-group mb-3">
      <input class="mc-checkout-checkbox-field" type="checkbox" id="changePwd" formControlName="changePwd"
        (change)="changeShowPwd($event)" />
      <label class="form-check-label" for="changePwd">
        <p class="par_2">{{ "personalData.txtChangePwd" | translate }}</p>
      </label>
    </div>
    <div *ngIf="showPwd">
      <div class="form-group">
        <label for="newPwd">
          <p class="par_2">{{ "personalData.txtnewPwd" | translate }}</p>
        </label>
        <div class="mc-password-field input-group mb-3">
          <input [type]="show_button[1] ? 'text' : 'password'" class="form-control" formControlName="newPwd"
            id="newPwd" />
          <i class="input-group-text" [class]="show_eye[1] ? 'bi bi-eye' : 'bi bi-eye-slash'"
            (click)="showPassword(1)"></i>
        </div>
      </div>
      <div class="form-group">
        <label for="repeatNewPwd">
          <p class="par_2">{{ "personalData.txtrepeatNewPwd" | translate }}</p>
        </label>
        <div class="mc-password-field input-group mb-3">
          <input [type]="show_button[2] ? 'text' : 'password'" class="form-control" formControlName="repeatNewPwd"
            id="repeatNewPwd" />
          <i class="input-group-text" [class]="show_eye[2] ? 'bi bi-eye' : 'bi bi-eye-slash'"
            (click)="showPassword(2)"></i>
        </div>
      </div>
    </div> -->
    <button 
      *ngIf="clickEditPersonalData"
      type="submit" 
      class="btn btn-primary" 
      [disabled]="personalDataForm.invalid" 
      (click)="onSubmit()">
      {{ "buttons.save" | translate }}
    </button>
  </form>
  <!-- Informativa modifica account -->
  <div class="row" [ngClass]="{'mt-3': isMobile}" *ngIf="!clickEditPersonalData">
    <div class="col-md-12 text-md-start delete-container" style="cursor: pointer;">
      <img class="pad-rig" [src]="assetImg['editIcon']" alt="editIcon">
      <span class="d-md-block delete info-edit" (click)="changeShowButtonSave()">
        {{ "personalData.txtInfoEditAccount" | translate }}
      </span>
      <div class="image-container" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()">
        <i class="bi bi-info-circle-fill icon-info px-1 icon-sm"></i>
        <div *ngIf="isTooltipVisible" class="tooltip">
          {{ "personalData.descInfoEditAccount" | translate }}
        </div>
      </div>
    </div>
  </div>
  <!-- Pulsante Cancella Account -->
  <div class="row mb-5">
    <div class="col-md-12 text-md-start delete-container" (click)="changeShowModal()">
      <i class="fa fa-trash text-red fz-25 delete-content" aria-hidden="true"></i>
      <span class="d-md-block delete">
        {{ "personalData.txtDelete" | translate }}
      </span>
    </div>
  </div>
  <app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
    <ng-container>
      <div class="Modal-container logged">
        <h3 class="mb-3 text-center">{{ "personalData.modal.title" | translate }}</h3>
        <p class="mb-3 text-center">{{ "personalData.modal.des" | translate }}</p>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary" (click)="onDelete()">{{
            "personalData.txtDelete" | translate }}</button>
        </div>
        <div class="d-flex justify-content-center">
          <span (click)="changeShowModal()" class="back" role="button"> {{ "common.cameBack" | translate
            }}</span>
        </div>
      </div>
    </ng-container>
  </app-custom-modal>
</div>
