import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { environment } from 'src/environments/environment';
import { LOGO, LOGO_MOBILE } from 'src/config/constats';
import { RouteService } from '../service/route.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  currentUrl: string = '';
  isLoginBox: boolean = true;
  logoUrl: string = LOGO;
  logoMobileUrl: string = LOGO_MOBILE;
  image: string = '/assets/img/loginpage-image.png';
  privacyPolicy: string = '';
  cookiePolicy: string = '';
  urlServizi: string = '';
  isMobile: boolean = false;
  isWidth: boolean = false;
  mondoConv: string = '';
  redirect: boolean = false;
  redirectUrl: string = '';

  constructor(
    private _routeService: RouteService,
    private route: ActivatedRoute,
    private router: Router,
    public _detectorService: DeviceService,
    public _translate: TranslateService,
  ) {
    this.currentUrl = window.location.pathname;
    _translate.get('login.urlServizioClienti').subscribe((res: string) => {
      this.urlServizi = res;
    });

    _translate.get('cookiePolicy').subscribe((res: string) => {
      this.cookiePolicy = res;
    });

    _translate.get('privacyPolicy').subscribe((res: string) => {
      this.privacyPolicy = res;
    });

    this.isMobile = this._detectorService._isMobile.getValue();

    this.isWidth = window.matchMedia('width: 1919px').matches;

    this.mondoConv = environment.magento.it;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if ('mode' in params) {
        if (params.mode === 'guest') {
          this.changeBox(false);
        } else if (params.mode === 'redirect') {
          this.redirect = true;
          this.redirectUrl = this._routeService.validateQueryParams(params.redirectUrl);
        } else {
          this.router.navigate(['/login']);
        }
      }
    });
  }

  changeBox(val: boolean) {
    this.isLoginBox = val;
  }
}
