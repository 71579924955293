import { ICONE_MENU } from "./images";

const iconeMenu = ICONE_MENU;

export const sectionsStructure = [
  {
    txt: 'menu.area',
    link: '/dashboard',
    des: '',
    img: '',
    icon: `${iconeMenu['dashboard']}`,
    iconM: `${iconeMenu['dashboardM']}`,
    allView: true,
    txtGoTo: '',
    showAdmin: false,
  },
  {
    txt: 'menu.orders',
    link: '/orders',
    des: 'dashboard.card.orders.des',
    img: 'assets/img/areaPersonale/ordini-icon.png',
    icon: `${iconeMenu['orders']}`,
    iconM: `${iconeMenu['ordersM']}`,
    allView: false,
    txtGoTo: 'dashboard.card.orders.goTo',
    showAdmin: true,
  },
  {
    txt: 'menu.vouchers',
    link: '/vouchers',
    des: 'dashboard.card.vouchers.des',
    img: 'assets/img/areaPersonale/buonispesa-illustration.svg',
    icon: `${iconeMenu['buonoBig']}`,
    iconM: `${iconeMenu['buonoBigM']}`,
    allView: false,
    txtGoTo: 'dashboard.card.vouchers.goTo',
    showAdmin: true,
    classImg: "vouchers-image"
  },
  {
    txt: 'menu.personalData',
    link: '/personalData',
    des: '',
    img: 'assets/img/areaPersonale/personal_data.svg',
    icon: `${iconeMenu['personalData']}`,
    iconM: `${iconeMenu['personalDataM']}`,
    allView: false,
    txtGoTo: 'dashboard.card.personalData.goTo',
    showAdmin: false,
  },
  {
    txt: 'menu.newsletter',
    link: '/newsletter',
    des: 'dashboard.card.newsletter.des',
    img: 'assets/img/areaPersonale/newsletter.svg',
    icon: `${iconeMenu['newsletter']}`,
    iconM: `${iconeMenu['newsletterM']}`,
    allView: false,
    txtGoTo: 'dashboard.card.newsletter.goTo',
    showAdmin: true,
  },
  {
    txt: 'menu.address',
    link: '/address',
    des: 'dashboard.card.address.des',
    img: 'assets/img/areaPersonale/address.svg',
    icon: `${iconeMenu['address']}`,
    iconM: `${iconeMenu['addressM']}`,
    allView: false,
    txtGoTo: 'dashboard.card.address.goTo',
    showAdmin: true,
  },
  {
    txt: 'menu.helper',
    link: 'https://www.mondoconv.it/servizio-clienti',
    des: 'dashboard.card.helper.des',
    img: 'assets/img/areaPersonale/help.svg',
    icon: `${iconeMenu['help']}`,
    iconM: `${iconeMenu['helpM']}`,
    allView: false,
    txtGoTo: 'dashboard.card.helper.goTo',
    showAdmin: false,
  }
];
export const exitSection = {
  txt: 'menu.exit',
  link: '/logout',
  des: '',
  img: '',
  icon: `${iconeMenu['logout']}`,
  iconM: `${iconeMenu['logoutM']}`,
  allView: true,
  txtGoTo: '',
  showAdmin: true,
};

export const searchSection = {
  txt: 'buttons.search',
  link: '/search',
  des: '',
  img: '',
  icon: `${iconeMenu['logout']}`,
  iconM: `${iconeMenu['logoutM']}`,
  allView: true,
  txtGoTo: '',
  showAdmin: true,
};
