<div class="row pb-md-2 pb-sm-0 no-border-mobile" [ngClass]="{ 'mb-1': !isMobile }">
  <div 
    class="row"
    *ngIf="!isMobile ;else orderDetailAddressMobile">
    <div 
      *ngIf="isAddress" 
      class="pb-md-0 pb-sm-4 mb-md-0 border-0 sm-border-bottom mb-sm-5 col-md-6">
      <h4 class="mb-3">
        {{ "commons.txtAddressShipping" | translate | uppercase }}
      </h4>
      <p class="mb-2">{{ order.nom }} {{ order.cog }}</p>
      <p class="mb-2">
        {{ address }} - {{ order.comune }}
      </p>
      <p class="mb-2">{{ order.cap }}</p>
    </div>
    <div 
      *ngIf="order.indf" 
      class="pb-md-0 pb-sm-4 mb-md-0 border-0 sm-border-bottom mb-sm-5 col-md-6">
      <h4 class="mb-3">
        {{ "commons.txtAddressBilling" | translate | uppercase }}
      </h4>
      <p class="mb-2">{{ order.nom }} {{ order.cog }}</p>
      <p class="mb-2">{{ order.indf }}</p>
      <p class="mb-2">
        {{ "addressData.txtZip" | translate | uppercase }} {{ order.capf }} -
        {{ order.cittaf }}
      </p>
      <p class="mb-2" *ngIf="order.codfisc">
        {{ "commons.txtFiscalCodeShort" | translate | uppercase }}
        {{ order.codfisc }}
      </p>
    </div>
    <div 
      *ngIf="isAddressWarehouse" 
      class="pb-md-0 pb-sm-4 mb-md-0 border-0 sm-border-bottom mb-sm-5 col-md-6">
      <h4 class="mb-3">
        {{ "commons.txtAddressWarehouse" | translate | uppercase }}
      </h4>
      <p class="mb-2">{{ order.indDep || order.indPv }}</p>
      <p class="mb-2">{{ order.comuneDep || order.comunePv }}</p>
      <p class="fw-semibold mb-3">
        <a class="textMaps" [href]="urlsWarehouseMaps" target="_blank">
          {{ "reservation.googleMaps" | translate }}
        </a>
      </p>
      <p class="fw-semibold mb-2">
        {{ "reservation.txtDate" | translate | uppercase }}
      </p>
      <p class="mb-2" [innerHTML]="order.orarioDep || order.orarioPv"></p>
    </div>
    <div 
      *ngIf="isAddressStore" 
      class="pb-md-0 pb-sm-4 mb-md-0 border-0 sm-border-bottom mb-sm-5 col-md-6">
      <h4 class="mb-3">
        {{ "commons.txtAddressStore" | translate | uppercase }}
      </h4>
      <p class="mb-2">{{ order.nomePv }}</p>
      <p class="mb-2">{{ order.indPv }}, {{ order.comunePv }}</p>
      <p class="fw-semibold mb-3">
        <a class="textMaps" [href]="urlsWarehouseMaps" target="_blank">
          {{ "reservation.googleMaps" | translate }}
        </a>
      </p>
    </div>
  </div>
  <ng-template #orderDetailAddressMobile>
    <div class="accordion" id="accordionPanelsAddress">
      <div *ngIf="isAddress" class="accordion-item">
        <h2 class="accordion-header" id="panelsAddress-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsAddress-collapseOne" aria-expanded="false"
            aria-controls="panelsAddress-collapseOne">
              {{ "commons.txtAddressShipping" | translate | uppercase }}
          </button>
        </h2>
        <div id="panelsAddress-collapseOne" class="accordion-collapse collapse"
          aria-labelledby="panelsAddress-headingOne">
          <div class="accordion-body">
            <p class="mb-2">{{ order.nom }} {{ order.cog }}</p>
            <p class="mb-2">
              {{ address }} - {{ order.comune }}
            </p>
            <p class="mb-2">{{ order.cap }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="order.indf" class="accordion-item">
        <h2 class="accordion-header" id="panelsAddress-headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsAddress-collapseTwo" aria-expanded="false"
            aria-controls="panelsAddress-collapseTwo">
            {{ "commons.txtAddressBilling" | translate | uppercase }}
          </button>
        </h2>
        <div id="panelsAddress-collapseTwo" class="accordion-collapse collapse"
          aria-labelledby="panelsAddress-headingTwo">
          <div class="accordion-body">
            <p class="mb-2">{{ order.nom }} {{ order.cog }}</p>
            <p class="mb-2">{{ order.indf }}</p>
            <p class="mb-2">
              {{ "addressData.txtZip" | translate | uppercase }} {{ order.capf }} -
              {{ order.cittaf }}
            </p>
            <p class="mb-2" *ngIf="order.codfisc">
              {{ "commons.txtFiscalCodeShort" | translate | uppercase }}
              {{ order.codfisc }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="isAddressWarehouse" class="accordion-item">
        <h2 class="accordion-header" id="panelsAddress-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsAddress-collapseThree" aria-expanded="false"
            aria-controls="panelsAddress-collapseThree">
            {{ "commons.txtAddressWarehouse" | translate | uppercase }}
          </button>
        </h2>
        <div id="panelsAddress-collapseThree" class="accordion-collapse collapse"
          aria-labelledby="panelsAddress-headingThree">
          <div class="accordion-body">
            <p class="mb-2">{{ order.indDep || order.indPv }}</p>
            <p class="mb-2">{{ order.comuneDep || order.comunePv }}</p>
            <p class="fw-semibold mb-3">
              <a class="textMaps" [href]="urlsWarehouseMaps" target="_blank">
                {{ "reservation.googleMaps" | translate }}
              </a>
            </p>
            <p class="fw-semibold mb-2">
              {{ "reservation.txtDate" | translate | uppercase }}
            </p>
            <p class="mb-2" [innerHTML]="order.orarioDep || order.orarioPv"></p>
          </div>
        </div>
      </div>
      <div *ngIf="isAddressStore" class="accordion-item">
        <h2 class="accordion-header" id="panelsAddress-headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsAddress-collapseFour" aria-expanded="false"
            aria-controls="panelsAddress-collapseFour">
            {{ "commons.txtAddressStore" | translate | uppercase }}
          </button>
        </h2>
        <div id="panelsAddress-collapseFour" class="accordion-collapse collapse"
          aria-labelledby="panelsAddress-headingFour">
          <div class="accordion-body">
            <p class="mb-2">{{ order.nomePv }}</p>
            <p class="mb-2">{{ order.indPv }}, {{ order.comunePv }}</p>
            <p class="fw-semibold mb-3">
              <a class="textMaps" [href]="urlsWarehouseMaps" target="_blank">
                {{ "reservation.googleMaps" | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="col-12 mt-sm-3 mb-md-2 pdf-box d-flex" [ngClass]="{'box-information-alert': isMobile}"
    *ngIf="order.consegnaKey === 'N' || order.consegnaKey === 'C'">
    <div class="row">
      <div class="col-md-1" style="display: grid;" [ngClass]="{'text-center mb-sm-1': isMobile}">
        <i class="bi bi-exclamation-triangle icon-modal justify-content-center"></i>
      </div>
      <div class="col-md-11">
        <div *ngIf="order.consegnaKey === 'N'">
          <span class="fw-semibold">{{ "services.modalNavettaRowOne" | translate }}</span>
          <ul>
            <li>{{ "services.modalNavettaRowTwo" | translate }}</li>
            <li>{{ "services.modalNavettaRowThree" | translate }}</li>
            <li>{{ "services.modalNavettaRowFour" | translate }}</li>
          </ul>
        </div>
        <div *ngIf="order.consegnaKey === 'C'">
          <span class="par_2 fw-semibold">{{ "C" | translate }}</span>
          <p>{{"services.descCorriereNazionale" | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="pb-md-0 mb-md-0 border-0 col-md-12 pb-sm-2 mb-sm-1"
  [ngClass]="{'sm-border-bottom border-bottom mb-sm-5 pb-sm-4': !isMobile}"></div>
</div>