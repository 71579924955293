import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { TranslateService } from '@ngx-translate/core';
import { WHATSAPP_URL } from 'src/config/social';
import { RESERVATION, ASSISTANCE} from 'src/config/images';

@Component({
  selector: 'app-trasporto-montaggio-modal',
  templateUrl: './trasporto-montaggio-modal.component.html',
  styleUrls: ['./trasporto-montaggio-modal.component.scss'],
})
export class TrasportoMontaggioModalComponent {
  @Output() closeModal = new EventEmitter<any>();

  image_square: any = RESERVATION['checkSquare'];
  image_wa: any = ASSISTANCE['wa'];
  isMobile: boolean = false;
  listTrasportoMontaggio: Array<{t: string, d: string}> = [];

  constructor(
    private _deviceService: DeviceService,
    private _translateService: TranslateService
  )  {

    for (let i = 1; i < 7; i++) {
      this.listTrasportoMontaggio.push({
        t: this._translateService.instant(`reservation.txtTitleCheckList${i}`),
        d: this._translateService.instant(`reservation.desTitleCheckList${i}`)
      })
   }

    this.isMobile = this._deviceService._isMobile.getValue();
  }

  close(event: any) {
    this.closeModal.emit(event);
  }

  goToWa() {
    window.open(WHATSAPP_URL, '_blank');
  }

}
