import { AutoLoginVsMagentoParams } from "src/entities/AutoLoginVsMagentoParams";
import { BehaviorSubject, catchError, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { ConversionUtils } from 'turbocommons-ts';
import { CustomCookieService } from "./customCookie.service";
import { HeadersService } from "./headers.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlService } from "./url.service";

@Injectable({
  providedIn: 'root',
})
export class AutoLoginVsMagento {

  autoLoginSubject$: BehaviorSubject<AutoLoginVsMagentoParams> = new BehaviorSubject<AutoLoginVsMagentoParams>({ email: '', password: '' });
  autoLoginVsMagento$: Observable<number>;

  constructor(
    private _customCookieService: CustomCookieService,
    private _urlService: UrlService,
    private _headersService: HeadersService,
    private http: HttpClient
  ) {
    /**
    * @param {Object} param : { email:string, password:string, firstname:string, lastname:string }
    * @param {string} pwd
    * @return {User} Return a object user and set islogged
    * @public
    */

    this.autoLoginVsMagento$ = this.autoLoginSubject$.pipe(
      switchMap((params) => {
        if (!params.email && !params.password) return of('');
        const encodeParams = ConversionUtils.stringToBase64(`${params.email}/${params.password}`)
        const url = this._urlService.getUrl('autoLoginVsMagento');

        return this.http
          .post(url, {
            token: encodeParams
          }, {
            headers: this._headersService.getHeaders(),
            observe: `body`,
            responseType: 'text'
          })
      }),
      shareReplay(1),
      map((response): any => {
        if (response) {
          const responseEn = JSON.parse(JSON.parse(response)); // bad code
          this._customCookieService.setCookie([
            {key:'auth_token', value: responseEn.token},
            {key:'auth_token_sso', value:  responseEn.token},
            {key:'customer', value: JSON.stringify(responseEn.customer)},
            {key:'user_firstname', value: responseEn.customer.firstname},
            {key:'user_lastname', value: responseEn.customer.lastname},
            {key:'controlCookie', value:  "true"},
            {key:'type', value:  "username"}
          ]);
        }
      }),
      catchError((e) => {
        const err = JSON.parse(e.error);
        this._customCookieService.deleteCookie(['auth_token', 'auth_token_sso']);
        if (e instanceof HttpErrorResponse && err.code == 400) {
          if ((err.message).includes('password')) return of(450);
          if ((err.message).includes('email')) return of(451);
        }
        return of(455);
      })
    )
  }
}
