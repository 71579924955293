import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from 'src/app/service/api.service';
import { UtilityService } from 'src/app/service/utility.service';
import { Order } from 'src/entities/order';

@Component({
  selector: 'app-button-edit-appointment',
  templateUrl: './edit-appointment.component.html',
  styleUrls: ['./edit-appointment.component.scss'],
})
export class EditAppointmentComponent {
  @Input() order!:Order;

  constructor(
    private router: Router,
    private _utilityService: UtilityService,
    private _apiService: ApiService
  ) {
  }

  goToReservation(orderId: string) {
    this.router.navigate(['/reservation', orderId]);
  }
  

   showButtonEditAppointment(order:Order) {
     return this._utilityService.getShowEditAppointment({
        order,
        isBoxOrder: true,
        isGuest: this._apiService.isGuest$.getValue()
      }
     );
   }
}

