import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { TrasportoMontaggioModalComponent } from "../../trasporto-montaggio-modal/trasporto-montaggio-modal.component";

@Injectable()
export class TrasportoMontaggioModalService {
    private rootViewContainer!: ViewContainerRef;

    constructor(private factoryResolver: ComponentFactoryResolver) {
        this.factoryResolver = factoryResolver;
    }
    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }
    addDynamicComponent() {
        const factory = this.factoryResolver.resolveComponentFactory(TrasportoMontaggioModalComponent);
        const component = factory.create(this.rootViewContainer.parentInjector);
        // Subscribe to the closeModal event and destroy the component
        component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

        this.rootViewContainer.insert(component.hostView);
    }

    removeDynamicComponent(component: ComponentRef<TrasportoMontaggioModalComponent>) {
        component.destroy();
    }
}
