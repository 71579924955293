import { ApiService } from "../service/api.service";
import { Component, EventEmitter, Output } from '@angular/core';
import { CustomCookieService } from "../service/customCookie.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-banner-bottom',
  templateUrl: './banner-bottom.component.html',
  styleUrls: ['./banner-bottom.component.scss'],
})
export class BannerBottomComponent {
  isBannerHidden:boolean = false;

  constructor(
    public _apiService: ApiService,
    private _customCookieService: CustomCookieService,
    private router: Router
  ) {
  }

  onActionClick(evt: MouseEvent) {
    const target = evt.target as Element;
    if (target && target.classList.contains('bnr-action')) {
      evt.preventDefault();
      this._apiService.isLogged$.next(false);
      this._customCookieService.deleteCookie(['auth_token', 'auth_token_sso']);
      this.router.navigate(['/login'], {queryParams: {mode: 'guest'}});
    }

    if (target && target.classList.contains('bnr-action-service')) {
      evt.preventDefault();
      const buttonToClick = document.getElementsByClassName('embeddedMessagingConversationButton')[0] as HTMLElement;
      buttonToClick.click();
    }
  }

  hideBanner() {
    this.isBannerHidden = true;
  }
}
