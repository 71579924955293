<div id="personal_area" class="container">
  <div class="row gap-50 justify-content-between">
    <div class="single_box col-lg-6" *ngFor="let element of dashboard">
      <ng-template [ngIf]="element.txt !== 'menu.helper'" [ngIfElse]="helper">
        <a class="container rounded shadow p-md-5 h-100 d-block" [routerLink]="[element.link]">
          <div class="row d-flex align-items-center">
            <div class="col-4">
              <img src="{{ element.img }}" class="w-100">
            </div>
            <div class="col-8" [ngClass]="isMobile ? 'text-start' : 'text-end'">
              <h3 class="text-uppercase mb-2">{{ element.txt | translate }}</h3>
              <p class="par_2 mb-3" *ngIf="element.link !=='/personalData'">{{ element.des | translate }}</p>
              <p class="par_2 mb-3" *ngIf="element.link ==='/personalData'">
                {{ desUser }}
              </p>
              <p class="text-red fw-bold">{{ element.txtGoTo | translate }} ></p>
            </div>
          </div>
        </a>
      </ng-template>
      <ng-template #helper>
        <a class="container rounded shadow p-md-5 h-100 d-block" href="https://www.mondoconv.it/servizio-clienti">
          <div class="row d-flex align-items-center">
            <div class="col-4">
              <img src="{{ element.img }}" class="w-100" alt="icon-helper">
            </div>
            <div class="col-8" [ngClass]="isMobile ? 'text-start' : 'text-end'">
              <h3 class="text-uppercase mb-2">{{ element.txt | translate }}</h3>
              <p class="par_2 mb-3" *ngIf="element.link !=='/personalData'">{{ element.des | translate }}</p>
              <p class="par_2 mb-3" *ngIf="element.link ==='/personalData'">
                {{ desUser }}
              </p>
              <p class="text-red fw-bold">{{ element.txtGoTo | translate }} ></p>
            </div>
          </div>
        </a>
      </ng-template>
    </div>
  </div>
</div>
