import { BehaviorSubject, map, Observable, of, Subject } from "rxjs";
import { HeadersService } from "./headers.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlService } from "./url.service";
import { User } from "src/entities/user";
import { isEmpty } from "lodash";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$: BehaviorSubject<User> = new BehaviorSubject<User>(new User);
  user!: User | string | null;
  fullnameObs$: Observable<any>;
  setFullname$:  BehaviorSubject<string> = new BehaviorSubject<string>('');
  fullname$: Subject<string> = new BehaviorSubject<string>(''); // fullname = user

  constructor(
    private _headersService: HeadersService,
    private _urlService: UrlService,
    private http: HttpClient
  ) {
    this.user = this.getUser();

    this.fullnameObs$ = this.setFullname$.pipe(
      map((fullname: string) => {
        this.fullname$.next(fullname);
      })
    );
  }

  edit(firstname: string, lastname: string) {
    if(!this.user) return of(false);
    const url = this._urlService.getUrl('editAccount');

    return this.http
      .post(url,
        {
          firstname,
          lastname,
          customer_id: this.getCustomerId()
        },{
        headers: this._headersService.getHeaders(true),
        observe: `body`,
        responseType: 'json'
      })
  }

  delete () {
    if(!this.user) return of(false);

    const url = this._urlService.getUrl('deleteAccount');

    return this.http
      .post(url, {
        customer_id: this.getCustomerId()
      },{
        headers: this._headersService.getHeaders(true),
        observe: `body`,
        responseType: 'json'
      })
  }

  setUser(user: User) {
    this.user$.next(user);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('email', user.email);
    if (user.sfId) localStorage.setItem('sfId', user.sfId);
  }

  getUser() {
    // eseguo questo controllo poiche l'oggetto user$ viene inizializzato come new e valori vuoti
    const { authToken} = this.user$.getValue();
    if (!isEmpty(authToken)) return this.user$.getValue();

    const user = localStorage.getItem('user') ?? '{}';
    return JSON.parse(user) as User;
  }

  getEmail() {
    if (isEmpty(this.user$.getValue().email)) return localStorage.getItem('email');
    return this.user$.getValue().email;
  }

  getSfId() {
    if (isEmpty(this.user$.getValue().sfId)) return localStorage.getItem('sfId');
    return this.user$.getValue().sfId;
  }

  getCustomerId() {
    return this.user$.getValue().id;
  }
}
