import { ComponentRef, Injectable, Type, ViewContainerRef } from "@angular/core";

@Injectable()
export class DynamicModalService<T> {
    private rootViewContainer!: ViewContainerRef;

    constructor() {}

    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }

    addDynamicComponent(componentType: Type<T>, inputs: Partial<T> = {}): ComponentRef<T> {
        const component: ComponentRef<T> = this.rootViewContainer.createComponent(componentType);

        // Object.assign(component.instance, inputs);

        this.rootViewContainer.insert(component.hostView);

        return component;
    }

    removeDynamicComponent(component: ComponentRef<T>) {
        component.destroy();
    }
}
