import { ACCEPTED_STATE } from 'src/config/constats';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../service/api.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Invoice } from 'src/entities/invoice';
import { InvoiceService } from '../service/invoice.service';
import { Order } from 'src/entities/order';
import { PaymentModalService } from '../service/modal/paymentModal.service';
import { RouteService } from '../service/route.service';
import { ServiceProductModalService } from '../service/serviceProductModal.service';
import { ShippingModalService } from '../service/modal/shippingModal.service';
import { User } from 'src/entities/user';
import { UserService } from '../service/user.service';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
  grandTotal$: Subject<number>;
  isLogged: boolean = false;
  isMobile: boolean;
  isParteOrdine: boolean = false;
  order$: BehaviorSubject<Order>;
  shippingCost$: Subject<number>;
  states = ACCEPTED_STATE;
  subtotal$: Subject<number>;
  urlsInvoice$: Observable<Invoice[]>;
  urlsWarehouseMaps: string = '';
  user: User = new User();
  typeModalService: string = '';
  isCambioConRitiro: boolean = false;
  typeModalCambioStato: string = '';

  constructor(
    private _apiService: ApiService,
    private _detectorService: DeviceService,
    private _invoiceService: InvoiceService,
    private _route: RouteService,
    private _userService: UserService,
    private _utilityService: UtilityService,
    private paymentModalService: PaymentModalService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private serviceProductModalService: ServiceProductModalService,
    private shippingModalService: ShippingModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
    this.isLogged = this._apiService.isLogged$.getValue();
    this.grandTotal$ = this._apiService.grandTotal$;
    this.shippingCost$ = this._apiService.shippingCost$;
    this.subtotal$ = this._apiService.subtotal$;
    this.user = this._userService.user$.getValue();
    this.order$ = this._apiService.order$;
    this.urlsInvoice$ = this._invoiceService.invoices$;
  }

  ngOnInit(): void {
    let params: Params;
    this.route.params.subscribe((response) => {
      params = response;
    });

    this._apiService.orders$.subscribe((response: Order[]) => {
      const order = this._utilityService.getOrderDetailByParams(
        params,
        response
      );
      if (!order) throw new Error('Order not exist');
      if (this._utilityService.checkIsParteOrdine(order.rev)) this.isParteOrdine = true;

      if (this._utilityService.checkIsRevisione(order.rev)) this.typeModalService = 'revisione';
      if (this._utilityService.checkIsSdoppiato(order.rev)) this.typeModalService = 'parte';

      this.order$.next(order);
    });

    window.scrollTo(0,0);
  }

  ngAfterViewInit() {
    if (this.order$.getValue()) {
      const { guid, prbu: prbuF, rev, statoKey, consegnaKey } = this.order$.getValue();
      const prbu = `${prbuF + rev}`;
      this.isCambioConRitiro = (statoKey === 'pronto' && consegnaKey == 'R');
      this.typeModalCambioStato = this.isCambioConRitiro ? 'cambioRitiro' : 'cambioTrasporto';
      this.urlsInvoice$ = this._invoiceService.cbkInvoice({ guid, prbu });
      this.urlsWarehouseMaps = this._utilityService.getUrlCoordinates(this.order$.getValue());
    }
  }

  openModal(e: Event, order: Order, saldo: number = 0) {
    e.preventDefault();
    this.paymentModalService.setRootViewContainerRef(this.viewContainerRef);
    this.paymentModalService.addDynamicComponent(order, saldo);
  }

  openModalShipping(e: Event) {
    e.preventDefault();
    this.shippingModalService.setRootViewContainerRef(this.viewContainerRef);
    this.shippingModalService.addDynamicComponent();
  }

  openModalServices(e: Event, type:string) {
    e.preventDefault();
    this.serviceProductModalService.setRootViewContainerRef(this.viewContainerRef);
    this.serviceProductModalService.addDynamicComponent(type);
  }

  cameBack() {
    this._route.back();
  }

  isNumeric(num: string){
    return !isNaN(+num)
  }
}
