<div *ngIf="order.dettagli.length > 0;else notProducts">
  <div class="row">
    <div class="col-md-6">
      <h4 class="mb-3">{{ "commons.txtArticles" | translate | uppercase }}</h4>
    </div>
    <div class="col-6 text-end d-md-block d-sm-none">
      <h4 class="mb-3">{{ "commons.txtPrice" | translate | uppercase }}</h4>
    </div>
  </div>
  <div class="row mb-md-5 mb-sm-4" *ngFor="let product of order.dettagli| filterProduct">
    <div class="col-md-3 col-sm-7 mb-md-0 mb-sm-2">
      <img [src]="product.imageUrl" [alt]="product.des" class="w-100" />
    </div>
    <div class="col-md-7">
      <div class="mb-2">
        <span class="d-inline-block me-2 product-name">{{ product.des | titlecase }}</span>
        <span class="par_2 font-italic">{{ product.sid }}</span>
      </div>
      <p class="par_2 mb-md-0 mb-sm-2">{{ product.tipologia }}</p>
      <p class="par_2 mb-md-0 mb-sm-2">{{ "cardProduct.txtQuantity" | translate | uppercase }}: {{ product.quantita }}</p>
      <p class="par_2 mb-md-0 mb-sm-2 pt-2 pb-2">
        <span *ngIf="product.elementi && product.elementi.length > 0">
          <span class="par_2 fw-bold detail-text-accordion">{{ "orders.detailProduct" | translate }}</span>
          <i class="bi bi-plus-circle-fill" *ngIf="!!!showDetails" (click)="showDetails = true"></i>
          <i class="bi bi-dash-circle-fill" *ngIf="!!showDetails" (click)="showDetails = false"></i>
        </span>
      </p>
      <app-assistance
        [isCardProduct]="true"
        [showButtonActions]="false"
        [order]="order"
        [isSingleProduct]="true"
        [product]="product"
      ></app-assistance>
    </div>
    <p class="col-sm-6 mb-3 d-md-none d-sm-block fw-semibold">{{ "commons.txtPrice" | translate | uppercase }}</p>
    <div class="col-md-2 col-sm-6 text-end">
      <p *ngIf="product.ccod" class="par_2 fw-bold">{{ grandTotal$ | async | currency: "EUR" }} </p>
      <p *ngIf="!product.ccod" class="par_2 fw-bold">{{ product.importo | currency: "EUR" }} </p>
    </div>
    <ng-container *ngIf="showDetails">
      <ng-container *ngFor="let elementi of product.elementi | filterProduct">
        <div class="row p-2">
          <div class="col-1 text-center" [ngClass]="{'p-0': isMobile}">
            <span class="par_2">{{ elementi.quantita }}</span>
          </div>
          <div class="col-9">
            <div>
              <span class="d-inline-block fw-bold">{{ elementi.des | titlecase }}</span>
            </div>
            <span class="par_2 mb-md-0 mb-sm-2">{{ elementi.tipologia }}</span>
          </div>
          <div class="col-2" [ngClass]="{'p-0': isMobile}">
            <p class="par_2 fw-bold text-right">{{ elementi.importo | currency: "EUR"}} </p>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="row pt-4 border-top">
    <div class="col-12 text-md-end">
      <div class="row mb-md-2 mb-sm-1">
        <div class="col-md-10 col-sm-6">
          <p class="par_2">{{ "cardProduct.txtSubtotal" | translate | titlecase }}</p>
        </div>
        <div class="col-md-2 col-sm-6 text-sm-end">
          <p class="par_2">{{ subtotal$ | async | currency: "EUR" }}</p>
        </div>
      </div>
      <div class="row mb-md-2 mb-sm-1">
        <div class="col-md-10 col-sm-6">
          <p class="par_2">{{ "cardProduct.txtShipping" | translate | titlecase }}</p>
        </div>
        <div class="col-md-2 col-sm-6 text-sm-end">
          <p class="par_2">{{ shippingCost$ | async | currency: "EUR" }} </p>
        </div>
      </div>
      <div class="row mb-md-2 mb-sm-1" *ngIf="order.impgeo > 0">
        <div class="col-md-10 col-sm-6">
          <p class="par_2">{{ "cardProduct.txtDiscount" | translate | titlecase }}</p>
        </div>
        <div class="col-md-2 col-sm-6 text-sm-end">
          <p class="par_2">- {{ order.impgeo | currency : "EUR" }}</p>
        </div>
      </div>
      <div class="row mb-md-2 mb-sm-1">
        <div class="col-md-10 col-sm-6">
          <p class="fw-bold">{{ "cardProduct.txtTotal" | translate | titlecase }}</p>
        </div>
        <div class="col-md-2 col-sm-6 text-sm-end">
          <p class="fw-bold">{{ grandTotal$ | async | currency: "EUR" }} </p>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #notProducts>
  <div class="row">
    <div class="col-md-12">
      <h4 class="mb-3">
        Non sono presenti prodotti nell'ordine
      </h4>
    </div>
  </div>
</ng-template>
