<div id="list_orders" class="container">
  <div class="row" [ngClass]="isMobile ? 'mb-3' : 'mb-5'">
    <div class="col-md-6 text-md-start text-sm-center text-sm-left-c mb-md-0"
      [ngClass]="isMobile ? 'mb-sm-1' : 'mb-sm-4'">
      <h1 class="border-bottom border-red border-3 pb-2 d-inline-block">
        <i class="bi bi-box2"></i>
        <i class="bi bi-check"></i>
        <span>{{ "menu.orders" | translate }}</span>
      </h1>
    </div>
    <div class="col-md-6 text-md-end text-sm-center text-sm-left-c" *ngIf="isLogged">
      <span class="par_2 text-bold fw-bold me-3">{{
        "orders.filter" | translate
        }}</span>
      <div ngbDropdown class="d-inline-block mc-dropdown shadow" [ngClass]="{ 'order-filter-mobile': isMobile2 }">
        <button type="button" class="border-0 bg-white par_2 active" ngbDropdownToggle
          [ngClass]="{ underline: isMobile2 }">
          {{ activeOrderBy }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdown1" class="border-0 rounded-0">
          <ng-container *ngFor="let item of ordinaItems">
            <button *ngIf="item !== activeOrderBy" ngbDropdownItem class="par_2" (click)="sortBy(item)">
              {{ item }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let order of sortBy(activeOrderBy) | keyvalue" class="single_order rounded shadow p-md-5 mb-5">
    <div class="container p-sm-0 pb-md-2 pb-sm-0 mb-4 no-border-mobile col-md-12 p-0">
      <div class="d-md-flex pb-md-1 pb-sm-0 mb-3 border-bottom col-md-12 p-0 justify-content-between" [ngClass]="isMobile ? 'view-guid' : 'd-sm-block'">
        <div class="col-md-10 pl-md-0 pr-md-3 px-sm-0 row-guid-content">
          <p class="par_2 fw-semibold mb-1 row-consegna">
            {{ order.value[0].consegnaKey | translate | uppercase }}
          </p>
        </div>
        <div class="col-md-2 pl-md-0 pr-md-3 px-sm-0 row-guid-container">
          <span class="row-guid-title">{{ 'orders.txtGuid' | translate }}</span>
          <p class="par_2 fw-semibold mb-1 row-guid">
            {{ order.value[0].guid | translate | uppercase }}
          </p>
        </div>
      </div>
      <div class="row mb-2 mb-md-0 mt-sm-0 mx-sm-0 border-bottom no-border-desk">
        <div class="d-md-flex d-sm-block col-md-12 col-sm-5 p-0 justify-content-between custom-class-order"
          *ngIf="!isMobile">
          <div class="col-md-4 pl-md-0 pr-md-3 px-sm-0 width-date-order-column">
            <p class="par_2 fw-semibold mb-1">
              {{ "orders.txtPurchaseMode" | translate | uppercase }}
            </p>
          </div>
          <div class="col-md-3 px-sm-0 width-date-order-column">
            <p class="par_2 fw-semibold mb-1">
              {{ "orders.txtState" | translate | uppercase }}
            </p>
          </div>
          <div class="col-md-1 px-sm-0 width-date-order-column">
            <p class="par_2 fw-semibold mb-1">
              {{ "orders.txtTotal" | translate | uppercase }}
            </p>
          </div>
          <div class="col-md-2 px-sm-0 width-date-order-column inlineFlex">
            <p class="par_2 fw-semibold">
              {{ "orders.txtDaSaldare" | translate | uppercase }}
            </p>
              <i *ngIf="getSum(order.value, 'saldo') > 0"
                class="bi bi-info-circle-fill px-1 icon-sm"
                (click)="
                  openModal(
                    $event,
                    order.value[0],
                    getSum(order.value, 'saldo')
                  )
                "></i>
          </div>
        </div>
        <div
          class="d-md-flex d-sm-block pb-md-4 pb-sm-0 mb-4 border-bottom no-border-mobile col-md-12 col-sm-7 p-0 justify-content-between custom-class-order"
          *ngIf="!isMobile">
          <div class="col-md-4 pl-md-0 pr-md-3 px-sm-0 width-date-order-column">
            <p class="par_2 mb-1">{{ order.value[0].canale }}</p>
          </div>
          <div class="col-md-3 px-sm-0 width-date-order-column inlineFlex">
            <p class="par_2">{{ order.value[0].stato }}</p>
              <i
                *ngIf="order.value[0].statoKey === states[1]"
                class="bi bi-info-circle-fill px-1 icon-sm"
                (click)="openModalGeometra($event, 'geometra')"></i>
          </div>
          <div class="col-md-1 px-sm-0 width-date-order-column">
            <p class="par_2 mb-1">
              {{ getSum(order.value, "totale") | currency : "EUR" }}
            </p>
          </div>
          <div class="col-md-2 px-sm-0 width-date-order-column">
              <p class="par_2 mb-1" [ngClass]="{'strikethrough': order.value[0].annullato}">
                {{ getSum(order.value, "saldo") | currency : "EUR" }} 
              </p>
          </div>
        </div>

        <!-- BEGIN template mobile -->
        <div class="d-md-flex d-sm-block col-md-12 p-0 justify-content-between" *ngIf="isMobile">
          <div class="row mb-1">
            <div class="col-5 px-md-3 px-sm-0">
              <p class="par_2 fw-semibold mb-1">
                {{ "orders.txtPurchaseMode" | translate | uppercase }}
              </p>
            </div>
            <div class="col-7 px-md-3 px-sm-0">
              <p class="par_2">{{ order.value[0].canale }}</p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-5 px-md-3 px-sm-0">
              <p class="par_2 fw-semibold mb-1">
                {{ "orders.txtState" | translate | uppercase }}
              </p>
            </div>
            <div class="col-7 px-md-3 px-sm-0">
              <p class="par_2 fw-semibold mb-1">{{ order.value[0].stato }}</p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-5 px-md-3 px-sm-0">
              <p class="par_2 fw-semibold mb-1">
                {{ "orders.txtTotal" | translate | uppercase }}
              </p>
            </div>
            <div class="col-7 px-md-3 px-sm-0">
              <p class="par_2">
                {{ getSum(order.value, "totale") | currency : "EUR" }}
              </p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-5 px-md-3 px-sm-0 inlineFlex">
              <p class="par_2 fw-semibold">
                {{ "orders.txtDaSaldare" | translate | uppercase }}
              </p>
              <i *ngIf="getSum(order.value, 'saldo') > 0"
                class="bi bi-info-circle-fill px-1 icon-sm"
                (click)="
                  openModal(
                    $event,
                    order.value[0],
                    getSum(order.value, 'saldo')
                  )
                "></i>
            </div>
            <div class="col-7 px-md-3 px-sm-0">
              <p class="par_2" [ngClass]="{'strikethrough': order.value[0].annullato}">
                {{ getSum(order.value, "saldo") | currency : "EUR" }}
              </p>
            </div>
          </div>
        </div>
        <container-element *ngIf="!!isMobile2" [ngSwitch]="order.value[0].statoKey" class="mb-3 p-0">
          <div class="border-top pt-md-4 pt-2" [ngClass]=" isMobile || isMobile2 ? 'col-12 mt-md-0 mt-sm-3' : 'col-12 mt-md-0 mt-sm-3 d-flex justify-content-end gap-3'">
              <div *ngIf="
                  !order.value[0].annullato &&
                  order.value[0].statoKey !== states[0] &&
                  order.value[0].statoKey !== states[5] &&
                  order.value[0].statoKey !== states[6] &&
                  order.value[0].email != ''
                ">
                <button class="btn btn-primary btnHeight" 
                  (click)="openModalPayByLink($event, order.value)" 
                  name="btnPayNow"
                  *ngIf="getSum(order.value, 'saldo') > 0">
                  <span *ngIf="(!(isAdmin | async))">
                    {{ "buttons.payNow" | translate | uppercase }}
                  </span>
                  <span *ngIf="((isAdmin | async))">
                    {{ "buttons.payNowAdmin" | translate | uppercase }}
                  </span>
                </button>
              </div>
               <!-- Start Pbl No Email -->
              <div [ngClass]="{ 'col-12': isMobile || isMobile2 }" *ngIf="order.value[0].email == '' && isGuest">
                <app-button-function-disabled 
                  [classBtn]="'btn btn-gray btnHeight'" 
                  [nameBtn]="'btnPayNowDisable'" 
                  [label]="'buttons.payNow'"
                  [condition]="getSum(order.value, 'saldo') > 0">
                </app-button-function-disabled>
              </div>
              <!-- Fine Pbl No Email -->
              <div *ngSwitchCase="states[5]">
                <app-button-more-info></app-button-more-info>
              </div>
              <div *ngSwitchCase="states[8]">
                <app-button-edit-appointment [order]="order.value[0]" [isGuest]="isGuest"></app-button-edit-appointment>
                <div
                  class="col-12 mt-md-0 mt-sm-3 row justify-content-end gap-3"
                  *ngIf="(!order.value[0].perann && !order.value[0].perapp) || order.value[0].blockReservation">
                  <app-order-delivery [isMobile]="isMobile" [isMsgRev]="order.value[0].blockReservation"></app-order-delivery>
                </div>
              </div>
            <div *ngSwitchCase="states[7]">
              <div class="col-12 mt-md-0 mt-sm-3 row justify-content-end gap-3">
                <app-order-delivery [isMobile]="isMobile" [isMsgRev]="order.value[0].blockReservation"></app-order-delivery>
              </div>
            </div>
            <div *ngSwitchCase="states[9]">
              <app-button-create-appointment [order]="order.value[0]" [isGuest]="isGuest"></app-button-create-appointment>
              <div *ngIf="order.value[0].blockReservation">
                <app-order-delivery [isMobile]="isMobile" [isMsgRev]="order.value[0].blockReservation"></app-order-delivery>
              </div>
            </div>
            <div *ngSwitchCase="states[10]">
              <p [innerHTML]="
                  'orders.txtDelivery'
                    | translate
                      : {
                          ddci: order.value[0].dcci | date : 'dd-MM-yyyy',
                          ddcf:
                            plusDays(order.value[0].dcci, 6)
                            | date : 'dd-MM-yyyy'
                        }
                " class="text-start fw-bold"></p>
            </div>
          </div>
        </container-element>
      </div>
      <ng-container *ngFor="let product of order.value; let index = index">
        <div class="row justify-content-between m-sm-0">
          <!-- Prodotti su tablet e desktop -->
          <div class="row mb-4" [ngClass]="isMobile ? 'col-12' : 'col-8'">
            <div class="p-0 col-3 mb-md-0 mb-sm-2 date-order-mobile">
              <img
                [src]="product.dettagli[0].imageUrl"
                [alt]="product.des"
                [ngClass]="isGuest ? 'product-img-guest' : 'product-img-logged'"/>
            </div>
            <div class="col-9 line-height">
              <div>
                <span class="d-inline-block me-1 fw-bold par_2 font-size-mobile">
                  {{ "orders.txtOrder2" | translate | uppercase }}:
                </span>
                <span class="par_2 font-italic font-size-mobile">{{ product.prbu + product.rev }}</span>
              </div>
              <div>
                <span class="d-inline-block me-1 fw-bold par_2 font-size-mobile">
                  {{ "orders.txtDate" | translate | uppercase }}:
                </span>
                <span class="par_2 font-italic font-size-mobile">{{ product.dbu | date : "dd-MM-yyyy" }}</span>
              </div>
              <div *ngIf="getSumProduct(product.dettagli) > 1">
                <!-- <span class="par_2 font-italic">{{ getSumProduct(product.dettagli) }}&nbsp;
                </span>-->
                <span class="d-inline-block me-1 par_2 font-size-mobile" *ngIf="!product.fuorizona || !product.isTraghetto">{{
                  (getSum(product.dettagli, "quantita", "trasporto", false) > 1 ? "commons.txtPlusArticles" : "commons.txtArticle" ) | translate | lowercase
                  }}</span>
              </div>
              <app-assistance
                [isCardProduct]="true"
                [showButtonActions]="false"
                [order]="product">
              </app-assistance>
              <app-container-servizi-mc [data]="product"></app-container-servizi-mc>
              <div class="mt-3" *ngIf="fnShowBtnOrderDetail(order.value[0])">
                <a (click)="goToDetailOrder(order.value[0].prbu + order.value[0].rev)" class="btn-link">
                  {{ 'orders.detailOrder' | translate }} 
                  <img class="arrow-go-to" src="/assets/img/arrow-order.svg">
                </a>
              </div>
            </div>
          </div>
          <div class="text-end pr-md-0 pl-md-3 px-sm-0" [ngClass]="isMobile ? 'col-12' : 'col-4'" *ngIf="!isMobile">
            <app-orders-actions
              [data]="product"
              [isDetail]="false">
            </app-orders-actions>
          </div>
        </div>
      </ng-container>
    </div>
    <container-element *ngIf="!!!isMobile2" [ngSwitch]="order.value[0].statoKey">
      <div class="border-top pt-md-4 pt-2">
        <div
          class="col-12 mt-md-0 mt-sm-3 d-flex justify-content-end gap-3"
          style="flex-direction: row;flex-wrap: wrap;">
          <div [ngClass]="{ 'col-12': isMobile || isMobile2 }" *ngIf="
              !order.value[0].annullato &&
              order.value[0].statoKey !== states[0] &&
              order.value[0].statoKey !== states[5] &&
              order.value[0].statoKey !== states[6] &&
              order.value[0].email != ''
            ">
            <button 
              class="btn btn-primary btnHeight" 
              (click)="openModalPayByLink($event, order.value)" 
              name="btnPayNow"
              *ngIf="getSum(order.value, 'saldo') > 0">
              <span *ngIf="(!(isAdmin | async))">
                {{ "buttons.payNow" | translate | uppercase }}
              </span>
              <span *ngIf="((isAdmin | async))">
                {{ "buttons.payNowAdmin" | translate | uppercase }}
              </span>
            </button>
          </div>
         <!-- Start Pbl No Email -->
          <div [ngClass]="{ 'col-12': isMobile || isMobile2 }" *ngIf="order.value[0].email == '' && isGuest">
            <app-button-function-disabled 
              [classBtn]="'btn btn-gray btnHeight'" 
              [nameBtn]="'btnPayNowDisable'" 
              [label]="'buttons.payNow'"
              [condition]="getSum(order.value, 'saldo') > 0">
            </app-button-function-disabled>
          </div>
          <!-- Fine Pbl No Email -->
          <div *ngSwitchCase="states[5]">
            <app-button-more-info></app-button-more-info>
          </div>
          <div *ngSwitchCase="states[8]">
            <app-button-edit-appointment [order]="order.value[0]" [isGuest]="isGuest"></app-button-edit-appointment>
            <div *ngIf="(!order.value[0].perann && !order.value[0].perapp) || order.value[0].blockReservation">
              <app-order-delivery [isMobile]="isMobile" [isMsgRev]="order.value[0].blockReservation"></app-order-delivery>
            </div>
          </div>
          <div *ngSwitchCase="states[7]">
            <div>
              <app-order-delivery [isMobile]="isMobile" [isMsgRev]="order.value[0].blockReservation"></app-order-delivery>
            </div>
          </div>
          <div *ngSwitchCase="states[9]">
            <app-button-create-appointment [order]="order.value[0]" [isGuest]="isGuest"></app-button-create-appointment>
            <div *ngIf="order.value[0].blockReservation">
              <app-order-delivery [isMobile]="isMobile" [isMsgRev]="order.value[0].blockReservation"></app-order-delivery>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="states[10]">
          <p [innerHTML]="
              'orders.txtDelivery'
                | translate
                  : {
                      ddci: order.value[0].dcci | date : 'dd-MM-yyyy',
                      ddcf:
                        plusDays(order.value[0].dcci, 6) | date : 'dd-MM-yyyy'
                    }
            " class="text-end fw-bold"></p>
        </div>
      </div>
    </container-element>
    <app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
      <ng-container *ngIf="typePopup === 'deleteAppointment'">
        <div class="Modal-container deleteAppointment">
          <h3 class="mb-3 text-center">
            {{ "reservation.titleDeleteReservation" | translate }}
          </h3>
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary w-100 mb-4" (click)="deleteReservation(order.value[0])"
              style="max-width: 360px">
              {{ "buttons.deleteAppointment" | translate }}
            </button>
          </div>
          <div class="d-flex justify-content-center">
            <span (click)="changeShowModal()" class="back" role="button">
              {{ "common.cameBack" | translate }}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="typePopup === 'confirmAppointment'">
        <div class="Modal-container confirmAppointment">
          <i class="bi bi-check-lg d-flex justify-content-center"></i>
          <p class="confirm mb-3">
            {{
            "reservation.confirm"
            | translate
            : { dapp: order.value[0].dapp | date : "dd-MM-yyyy" }
            }}
          </p>
          <span class="back" (click)="goToOrder()">{{
            "reservation.back" | translate
            }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="typePopup === 'confirmDeleteAppointment'">
        <div class="Modal-container confirmAppointment">
          <i class="bi bi-check-lg d-flex justify-content-center"></i>
          <p class="confirm mb-3">
            {{ "reservation.msgConfirmDeleted" | translate }}
          </p>
          <span class="back" (click)="goToOrder()">{{
            "reservation.back" | translate
            }}</span>
        </div>
      </ng-container>
    </app-custom-modal>
  </div>
</div>
<!-- <app-banner-bottom></app-banner-bottom> -->
<app-loader *ngIf="loading$ | async"></app-loader>
