<div class="Modal" (click)="closeAdmin($event)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content" id="pay-by-link-admin">
      <i class="bi bi-x custom-close" (click)="closeAdmin($event)"></i>
      <div class="mobileHeader">
        <i class="bi bi-x custom-close" (click)="closeAdmin($event)"></i>
      </div>
      <div class="Modal-container">
          <div class="Modal-container">
            <i class="bi bi-check-lg d-flex justify-content-center"></i>
            <h3 class="mb-3 text-center">
              {{ "payByLink.titleAdmin" | translate }}
            </h3>
            <div class="justify-content-center">
              <p class="text-center mb-3">{{ "payByLink.descAdmin" | translate }}</p>
            </div>
            <div
              class="pblAdmin justify-content-center">
              <p
                class="fw-bold mb-3">{{ "payByLink.linkPbl" | translate }}:
              </p>
              <p
                [ngClass]="{'overflowAny': isMobile}"
                class="mb-3">
                {{ payByLinkInfoModal.link }}
              </p>
              <p
                class="fw-bold mb-3">
                {{ 'vouchers.txtExpire'| translate }}:
              </p>
              <p
                class="mb-3">
                {{ payByLinkInfoModal.exp | date:'dd-MM-yyyy HH:mm:ss' }}
              </p>
              <p
                class="fw-bold mb-3">
                {{ "orders.txtPayment" | translate }}:
              </p>
              <p
                class="mb-3">
                {{ payByLinkInfoModal.payment }}
              </p>
              <p
                class="fw-bold mb-3">
                {{ 'personalData.txtEmail' | translate }}:
              </p>
              <p
                class="mb-3">
                {{ payByLinkInfoModal.email }}
              </p>
              <p
                class="fw-bold mb-3">
                {{ "sms" | translate | uppercase }}:
              </p>
              <p
                class="mb-3">
                {{ payByLinkInfoModal.sms }}
              </p>
            </div>
            <div class="d-flex justify-content-center flex-row flex-wrap gap-4">
              <span class="back" (click)="goToOrders()">
                {{ "reservation.back" | translate }}:
              </span>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
