import { Component, Input, ViewContainerRef } from '@angular/core';
import { Router } from "@angular/router";
import { FunzioneDisabilitataComponent } from 'src/app/modal/funzione-disabilitata/funzione-disabilitata.component';
import { DynamicModalService } from 'src/app/service/modal/dynamicModal.service';

@Component({
  selector: 'app-button-function-disabled',
  templateUrl: './function-disabled.component.html',
  styleUrls: ['./function-disabled.component.scss'],
})
export class FunctionDisabledComponent extends DynamicModalService<FunzioneDisabilitataComponent> {
  @Input() classBtn!: string;
  @Input() nameBtn!: string;
  @Input() condition?: boolean = true;
  @Input() label!: string;

  constructor(
    private router: Router,
    private viewContainerRef: ViewContainerRef,
  ) {
    super();
  }

  openModalFunctionDisabled(e: Event) {
    e.preventDefault();
    
    this.setRootViewContainerRef(this.viewContainerRef);

    const componentRef = this.addDynamicComponent(FunzioneDisabilitataComponent);

    componentRef.instance.closeModal.subscribe(() => {
        this.removeDynamicComponent(componentRef);
    });
  }
}

