export const FACEBOOK_APP_ID = '754440630010521'; // OLD: '869619700930730';
export const FACEBOOK_APP_ID_TEST = '1161029941356578';
export const FACEBOOK_APP_TOKEN = '869619700930730|Zj_LsWdEPCVzbmapy6lpZ_2RORo'
export const FACEBOOK_URL_VALIDATION_TOKEN = 'https://graph.facebook.com/debug_token?';

export const GOOGLE_APP_ID = '859819165283-14oeb33f4cojf2ov91uk1vh1jt8fj1ab.apps.googleusercontent.com';
export const GOOGLE_APP_ID_TEST = '1081613904887-719osjbdv1u3krg7ur1pq5si2aempah7.apps.googleusercontent.com';
export const GOOGLE_URL_VALIDATION_TOKEN = 'https://oauth2.googleapis.com/tokeninfo?id_token=';

export const WHATSAPP_URL = 'https://api.whatsapp.com/send/?phone=393896583091&text&app_absent=0';
