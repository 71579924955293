<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <h3 class="mb-3 text-center">{{ "guestBox.controlCode" | translate }}</h3>
        <p class="mb-3">{{ "guestBox.descModal" | translate }}</p>
        <div class="text-center">
          <img 
            [src]="image_control_code" 
            alt="control code"
            class="mb-3" 
            [ngClass]="{'img-mobile': isMobile}">
        </div>
      </div>
    </div>
  </div>
</div>

