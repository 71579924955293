import { BehaviorSubject, map, Observable, of, retry, switchMap } from "rxjs";
import { HeadersService } from "./headers.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrdiniUtiltyService } from "./ordiniUtility.service";
import { UrlService } from "./url.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root',
})
export class OrdiniService {
  headers: HttpHeaders;
  ordini$: Observable<any>;

  private cbkOrdini$: BehaviorSubject<string>;

  constructor(
    private _headersService: HeadersService,
    private _ordiniUtility: OrdiniUtiltyService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient
  ) {
    this.headers = this._headersService.getHeaders(true);
    this.cbkOrdini$ = new BehaviorSubject<any>('');

    this.ordini$ = this.cbkOrdini$.pipe(
      switchMap((email) => {
        if (!email) return of('');
        const url = `${this._urlService.getUrl('order')}${email}`;

        return this.http
        .get<any>(url, { headers: this.headers })
      }),
      map((response: any) => {
        return this._ordiniUtility.refactoringObjOrders(response);
      }),
      retry(1),
    )
  }

  cbkOrdini() {
    const email = this._userService.user$.getValue().email;
    this.cbkOrdini$.next(email);
  }
}

