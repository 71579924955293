<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content" id="modal-trasporto-montaggio">
      <i class="bi bi-x custom-close" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <i class="bi bi-x custom-close" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <p class="mb-3 title">{{ "reservation.txtTitleTM" | translate }}</p>
        <p class="mb-3 text-center" [innerHTML]="'reservation.txtSubTitleTM' | translate"></p>
        <div class="d-flex justify-content-center flex-column">
          <div *ngFor="let elem of listTrasportoMontaggio" class="container-element">
            <div class="container-img">
              <img class="mb-2" [src]="image_square" alt="img-square"/>
            </div>
            <div class="container-text">
              <p>{{ elem.t | translate }}</p>
              <p class="mb-3" [innerHTML]="elem.d"></p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-row gap-4 mb-4 mt-4">
          <p class="mb-3">{{ "reservation.txtSubTitle3" | translate }}</p>
        </div>
        <div class="d-flex justify-content-center flex-row">
          <button
            class="btn btn-green btn-wa p-1"
            (click)="goToWa()"
            name="btnWa">
            <img class="img-wa" [src]="image_wa" alt="img-wa"/>
            {{ "reservation.txtBtnContact" | translate | uppercase }}
        </button>
        </div>
        <span class="cameback" (click)="closeModal.emit(false)">{{ "common.cameBack" | translate }}</span>
      </div>
    </div>
  </div>
</div>
