import { Component, EventEmitter, Output, Input } from '@angular/core';
import { DeviceService } from '../../service/device.service';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent {
  @Output() closeModal = new EventEmitter<any>();

  @Input() orderId!: string;
  isMobile: boolean = false;
  
  constructor(
    private _deviceService: DeviceService,
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }

  close(event: any) {
    this.closeModal.emit(event);
  }
}
