<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <div id="barcode-modal" class="row pb-sm-0">
          <div class="row col-12 text-center">
            <!-- insert your custom barcode setting your data in the GET parameter "data" -->
            <img 
              alt='{{orderId}}'
              src='https://barcode.tec-it.com/barcode.ashx?data={{orderId}}&code=Code128&translate-esc=on' />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

