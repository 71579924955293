import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceService } from '../../service/device.service';
import { RouteService } from 'src/app/service/route.service';

@Component({
  selector: 'app-funzione-disabilitata',
  templateUrl: './funzione-disabilitata.component.html',
  styleUrls: ['./funzione-disabilitata.component.scss'],
})
export class FunzioneDisabilitataComponent {
  @Output() closeModal = new EventEmitter<any>();

  isMobile: boolean = false;

  constructor(
    private _deviceService: DeviceService,
    private _routeService: RouteService,
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }

  close(event: any) {
    this.closeModal.emit(event);
  }

  goToSignUp() {
    this._routeService.goTo('signup');
  }

  goToSignIn() {
    this._routeService.goTo('login');
  }

}
