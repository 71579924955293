import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { UtilityService } from 'src/app/service/utility.service';
import { Order } from 'src/entities/order';

@Component({
  selector: 'app-button-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss'],
})
export class CreateAppointmentComponent {
  @Input() order!:Order;
  @Input() isGuest!:boolean;

  constructor(
    private router: Router,
    private _utilityService: UtilityService,
  ) {
  }

  goToReservation(orderId: string) {
    this.router.navigate(['/reservation', orderId]);
  }
  

  showButtonCreateAppointment(order:Order) {
    return this._utilityService.getShowCreateAppointment({
      order, 
      isBoxOrder: true,
      isGuest: this.isGuest,
      useCondition: true
    })
  }
}

