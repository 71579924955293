import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { LoginAdminFailComponent } from "src/app/modal/login-admin-fail/login-admin-fail.component";

@Injectable()
export class LoginAdminFailService {
    private rootViewContainer!: ViewContainerRef;

    constructor() {}

    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }
    addDynamicComponent() {
        const component:ComponentRef<LoginAdminFailComponent> = this.rootViewContainer.createComponent(LoginAdminFailComponent);
        component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

        this.rootViewContainer.insert(component.hostView);
    }

    removeDynamicComponent(component: ComponentRef<LoginAdminFailComponent>) {
        component.destroy();
    }
}
