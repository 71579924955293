import { AdminService } from "../admin.service";
import { ApiService } from "../api.service";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { HeadersService } from "../headers.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PayByLinkComponent } from "../../modal/pay-by-link/pay-by-link.component";
import { Payment } from "src/entities/Payment";
import { PaymentEmail } from "src/entities/PaymentEmail";
import { PaymentRes } from "src/entities/PaymentRes";
import { UrlService } from "../url.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: 'root',
})
export class PayByLinkService {
  private rootViewContainer!: ViewContainerRef;

  headers: HttpHeaders;
  isAdmin: Subject<boolean> = new BehaviorSubject<boolean> (false);

  constructor(
    private _adminService: AdminService,
    private _apiService: ApiService,
    private _headersService: HeadersService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient
  ) {
    this.isAdmin = this._adminService.isAdmin$;
    this.headers = this._headersService.getHeaders();
  }

  payByLink(params: Payment): Observable<PaymentRes> {
    if (!params.pagamento || !params.ordini) return of(
      {
        "resultCode": "400",
        "errDescription": "Wrong Params",
        "url": "",
        "expire": ""
      } as PaymentRes);
    const url = this._urlService.getUrl('startUserPayment');

    if(this._apiService.isGuest$.getValue()) {
      params.email = params.email;
    } else if(this._userService.getEmail() != null) {
      params.email = this._userService.getEmail() || '';
    }

    params.pagamento =  (params.pagamento).toUpperCase();

    return this.http
      .post<any>(url,
        params, {
        headers: this.headers,
        observe: `body`,
        responseType: 'json'
      })
  }

  sendEmailPbn(params: PaymentEmail): Observable<any> {
    if (!params.pagamento || !params.ordini) return of(); // gestire errore
    const url = this._urlService.getUrl('sendEmailPbl');

    params.pagamento =  (params.pagamento).toUpperCase();

    return this.http
      .post<any>(url,
        params, {
        headers: this.headers,
        observe: `body`,
        responseType: 'json'
      })
  }

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }
  addDynamicComponent(order:any, isAdmin:boolean) {
      const component:ComponentRef<PayByLinkComponent> = this.rootViewContainer.createComponent(PayByLinkComponent);
      component.instance.order = order;
      component.instance.isAdmin = isAdmin;
      component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

      this.rootViewContainer.insert(component.hostView);
  }

  removeDynamicComponent(component: ComponentRef<PayByLinkComponent>) {
      component.destroy();
  }
}
