import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { PaymentModalComponent } from "../../payment-modal/payment-modal.component";

@Injectable()
export class PaymentModalService {
    private rootViewContainer!: ViewContainerRef;

    constructor(private factoryResolver: ComponentFactoryResolver) {
        this.factoryResolver = factoryResolver;
    }
    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }
    addDynamicComponent(order: any, saldo: number = 0) {
        const factory = this.factoryResolver.resolveComponentFactory(PaymentModalComponent);
        const component = factory.create(this.rootViewContainer.parentInjector);
        component.instance.order = order;
        component.instance.saldo = saldo;
        // Subscribe to the closeModal event and destroy the component
        component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

        this.rootViewContainer.insert(component.hostView);
    }

    removeDynamicComponent(component: ComponentRef<PaymentModalComponent>) {
        component.destroy();
    }
}
