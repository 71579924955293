import {ApplicationRef, Injectable} from "@angular/core";
import {SwUpdate} from "@angular/service-worker";
import {concat, first, interval} from "rxjs";

const LOG_PREFIX = '[Service][LogUpdate] ';

@Injectable({
  providedIn: 'root',
})
export class NewVersionCheckerService {

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate
  ) {}

  init() {
    if (this.swUpdate.isEnabled) {
      // Allow the app to stabilize first, before starting
      // polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.swUpdate.checkForUpdate();
          console.debug(LOG_PREFIX + (updateFound ? 'A new version is available.' : 'Already on the latest version.'));
          if (updateFound) {
            const isNewVersionActivated = await this.swUpdate.activateUpdate();
            // Check if the new version is activated and reload the app if it is
            if (isNewVersionActivated) window.location.reload();
          }
        } catch (err) {
          console.error(LOG_PREFIX + 'Failed to check for updates:', err);
        }
      });
    }


    this.swUpdate.versionUpdates.subscribe(evt => {
      console.debug(LOG_PREFIX + `versionUpdates type: ${evt.type}`);
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.debug(LOG_PREFIX + `Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.debug(LOG_PREFIX + `Current app version: ${evt.currentVersion.hash}`);
          console.debug(LOG_PREFIX + `New app version ready for use: ${evt.latestVersion.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.debug(LOG_PREFIX + `Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }
}
