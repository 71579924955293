import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ReservationService {

  private _reservation = new BehaviorSubject<string[]>([]);
  public readonly reservation$ = this._reservation.asObservable();

  constructor() {}

  setReservation(v: string) {
    const currentReservations: string[] = this._reservation.getValue();
    const updatedReservations: string[] = [...currentReservations, v];
    this._reservation.next(updatedReservations);
  }

  removeReservation(v: string) {
    const currentReservations: string[] = this._reservation.getValue();
    const updatedReservations: string[] = currentReservations.filter(item => item !== v);
    this._reservation.next(updatedReservations);
  }

  getReservation(): string[] {
    return this._reservation.getValue();
  }
}
