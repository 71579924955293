import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { BarcodeComponent } from "src/app/modal/barcode/barcode.component";

@Injectable()
export class BarcodeService {
    private rootViewContainer!: ViewContainerRef;

    constructor() {}

    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }
    addDynamicComponent(orderId:string) {
        const component:ComponentRef<BarcodeComponent> = this.rootViewContainer.createComponent(BarcodeComponent);
        component.instance.orderId = orderId;
        component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

        this.rootViewContainer.insert(component.hostView);
    }

    removeDynamicComponent(component: ComponentRef<BarcodeComponent>) {
        component.destroy();
    }
}
