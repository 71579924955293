<table class="table border-bottom" *ngIf="!isMobile">
  <thead class="table-primary align-middle">
    <tr>
      <th scope="col">
        {{ "commons.txtArticles" | translate | uppercase }}
      </th>
      <th scope="col">
        {{ "table.txtSid" | translate | uppercase }}
      </th>
      <th scope="col">
        {{ "table.txtDes" | translate | uppercase }}
      </th>
      <th scope="col">
        {{ "table.txtQta" | translate | uppercase }}
      </th>
      <th scope="col">
      </th>
      <th scope="col">
        {{ "commons.txtPrice" | translate | uppercase }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let product of order.dettagli | filterProduct">
      <tr>
        <td>
          <div class="picsum-img-wrapper">
            <img [src]="product.imageUrl" [alt]="product.des" class="w-100 img-max"/>
          </div>
        </td>
        <td>
          {{ product.sid }}
        </td>
        <td>
          {{ product.des | titlecase }}
        </td>
        <td class="text-center">
          {{ product.quantita }}
        </td>
        <td *ngIf="!product.ccod">
          {{ product.importo | currency: "EUR" }}
        </td>
        <td *ngIf="product.ccod">
          {{ grandTotal$ | async | currency: "EUR" }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div class="border-bottom" *ngIf="isMobile">
  <div class="row mb-md-5 mb-sm-4" *ngFor="let product of order.dettagli">
    <div class="col-md-3 col-sm-7 mb-md-0 mb-sm-2">
      <img [src]="product.imageUrl" [alt]="product.des" class="w-100"/>
    </div>
    <div class="col-md-7">
      <div class="mb-2">
        <p class="d-inline-block me-2 product-name">{{ product.des | titlecase }}</p>
        <span class="par_2 font-italic">{{ product.sid }}</span>
      </div>
      <p class="par_2 mb-md-0 mb-sm-2">{{ product.tipologia }}</p>
      <p class="par_2 mb-md-0 mb-sm-2">{{ "cardProduct.txtQuantity" | translate | uppercase }}: {{
        product.quantita }}</p>
    </div>
    <p class="col-sm-6 mb-3 d-md-none d-sm-block fw-semibold">{{ "commons.txtPrice" | translate | uppercase }}
    </p>
    <div class="col-md-2 col-sm-6 text-end">
      <p class="par_2 fw-bold">{{ product.importo | currency: "EUR" }}</p>
    </div>
  </div>
</div>
