<div id="box_form" class="shadow bg-white">
    <h1 class="text-center mb-md-4 mb-sm-3">{{ "guestBox.title" | translate }}</h1>
    <p class="text-center mb-3 mb-md-5" [innerHTML]="'guestBox.subTitle' | translate"></p>
    <form [formGroup]="guestBoxForm" class="mb-4">
        <div class="row mb-md-4 mb-sm-3">
            <div class="form-guestBoxForm w-100">
                <input
                  class="form-control"
                  id="controlCode"
                  name="controlCode"
                  type="text"
                  formControlName="controlCode"
                  [placeholder]="'guestBox.controlCode' | translate"
                  oninput="this.value = this.value.toUpperCase()"/>
                <app-error-form [fieldControl]="guestBoxForm.controls['controlCode']"
                    codeError="errors.controlCode"></app-error-form>
            </div>
        </div>
    </form>
    <button (click)="onSubmit()" type="submit" class="btn btn-primary w-100 mb-4">{{ "buttons.convalida" | translate }}</button>
    <a class="text-center guestBox-desc" (click)="openModalGuestBox($event, true)">{{ "guestBox.desc" | translate }}</a>
    <p class="par_2 text-center des-client mb-3">
        {{ "createAccount.desClient1" | translate }}&nbsp;
        <span (click)="setLoginBox(true)">{{ "buttons.access" | translate }}</span>
    </p>
    <p class="par_2 text-center des-client">
        {{ "guestBox.desClient1" | translate }}&nbsp;
        <span (click)="goToSignUp()">{{ "buttons.signUp2" | translate }}</span>
    </p>
</div>