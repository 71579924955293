import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../service/api.service';
import { GtmService } from '../service/gtm.service';
import { UserService } from '../service/user.service';
import { AdminService } from '../service/admin.service';

@Injectable({
  providedIn: 'root'
})


export class RouteGuard implements CanActivate {
  currentLanguage: string = '';
  defaultRoute: string = '';
  constructor(
    private _adminService: AdminService,
    private _apiService: ApiService,
    private _gtmService: GtmService,
    private _userService: UserService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this._apiService.isLogged$.getValue() && !this._apiService.isGuest$.getValue()) {
      this._gtmService.eventNotLogged(window.location.pathname);
      this.router.navigate(['/login']);
      return false;
    }

    if (this._apiService.isGuest$.getValue()) this._gtmService.eventNotLogged(window.location.pathname);
    if (this._apiService.isLogged$.getValue() && !this._adminService.isAdmin$.getValue()) this._gtmService.eventLogged(this._userService.getUser(), window.location.pathname);

    return true;
  }
}
