import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceService } from 'src/app/service/device.service';
import { PblInfo } from 'src/entities/PblInfo';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pay-by-link-admin',
  templateUrl: './pay-by-link-admin.component.html',
  styleUrls: ['../pay-by-link/pay-by-link.component.scss'],
})
export class PayByLinkAdminComponent {
  @Input() payByLinkInfoModal!:PblInfo;
  @Output() closeModalAdmin = new EventEmitter<any>();

  isMobile: boolean = false;

  constructor(
    private _deviceService: DeviceService,
    private router: Router,
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }

  closeAdmin(event: any) {
    this.clickButtonWithClass('custom-close');
    this.closeModalAdmin.emit(event);
  }

  clickButtonWithClass(className: string): void {
    const button = document.querySelector(`.${className}`) as HTMLElement;
    if (button) button.click(); 
  }

  goToOrders () {
    this.router.navigate(['/orders']);
  };
}
