
export const LVL_ONE_ASS_PRODOTTO = 1;
export const LVL_ONE_ASS_ORDINE = 2;
export const LVL_ONE_ASS_CONSEGNA = 3;
export const LVL_ONE_ASS_ALTRO = 4;

export const LVL_TWO_PRODOTTO_ART_DANNEGGIATO =1;
export const LVL_TWO_PRODOTTO_ART_MANCANTI = 2;
export const LVL_TWO_PRODOTTO_ART_ERRATO = 3;
export const LVL_TWO_PRODOTTO_ART_DIFETTOSO = 4;
