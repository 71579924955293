import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()

export class CustomCookieService {
  domain: string = 'localhost';
  constructor(
    private _cookieService: CookieService
  ) {
    if (location.host === 'account-sg.mondoconv.it' || location.host === 'account.mondoconv.it') this.domain = '.mondoconv.it';
  }

  /**
   * Returns the cookie if any, else false
   * @param name
   */
  public getCookie(name: string): string | false {
    if (!this._cookieService.check(name)) return false;
    return this._cookieService.get(name);
  }

  public deleteCookie(values: string[]) {
    if(values) {
      values.map((e) => {
        this._cookieService.delete(e, "/", this.domain);
      });
    }
  }

  public setCookie(values: { key: string; value: any; }[]) {
    values.map((e) => {
      // expires cookie 2 days
      this._cookieService.set(e.key, e.value, 2, '/', this.domain);
    })
  }
}
