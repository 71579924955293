<button class="btn btn-blue-reverse" (click)="changeShowModal()" *ngIf="showBtnAssistance && showButtonActions">
  {{ "buttons.askAssistance" | translate }} >
</button>
<!-- template gruppo prodotti lista -->
<p
  class="par_2 mb-md-0 assistance"
  *ngIf="conditionAssistenceRequired && !showButtonActions && !conditionSingleAssistenceRequired">
      {{ "cardProduct.txtAssistance" | translate }}
</p>

<!-- template singolo prodotto dettaglio -->
<p
  class="par_2 mb-md-0 assistance"
  *ngIf="conditionSingleAssistenceRequired && isCardProduct && isSingleProduct">
      <span class="content">{{ "cardProduct.txtAssistance" | translate }} </span>
</p>

<app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" (previousStepModal)="previousStep()" [curStep]="step" modalClass="assistance">
  <ng-container>
    <ng-container *ngIf="step == 1">
      <div class="Modal-container logged">
        <h3 class="mb-3 text-center">{{ "assistance.txtAsk" | translate }}</h3>
        <p class="mb-3 text-center">{{ "assistance.txtDescTwo" | translate }}</p>
        <div class="d-flex justify-content-between flex-row flex-wrap gap-4 mb-4">
          <div class="box-container" (click)="nextStep()" [ngClass]="{'selectedBox': selLvlTwo == 1, 'disabled-click': disableAssFirstLvlProduct}">
            <img [src]="assetImg['prodotto']" alt="prodotto"/>
            <p class="fw-bold text-center">{{ "assistance.txtAssistenzaProdotto" | translate }}</p>
          </div>
          <div class="box-container" (click)="nextStep(5); updateValueLvlOne(lvl_one_ass_ordine)" [ngClass]="{'selectedBox': selLvlTwo == 2}">
            <img [src]="assetImg['ordine']" alt="ordine"/>
            <p class="fw-bold text-center">{{ "assistance.txtAssistenzaOrdine" | translate }}</p>
          </div>
          <div class="box-container" (click)="nextStep(5); updateValueLvlOne(lvl_one_ass_consegna)" [ngClass]="{'selectedBox': selLvlTwo == 3}">
            <img [src]="assetImg['consegna']" alt="consegna"/>
            <p class="fw-bold text-center">{{ "assistance.txtAssistenzaConsegna" | translate }}</p>
          </div>
          <div class="box-container" (click)="nextStep(5); updateValueLvlOne(lvl_one_ass_altro)" [ngClass]="{'selectedBox': selLvlTwo == 4}">
            <img [src]="assetImg['altrotipo']" alt="altro-tipo"/>
            <p class="fw-bold text-center">{{ "assistance.txtAssistenzaAltro" | translate }}</p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="step == 2">
      <div class="Modal-container">
        <h3 class="mb-3 text-center">{{ "assistance.txtAsk" | translate }}</h3>
        <p class="mb-3 text-center subtitleOther">{{ "assistance.txtAssistenzaProdotto" | translate }}</p>
        <p class="mb-4 text-center descriptionOther">{{ "assistance.txtProduct" | translate }}</p>
        <div class="d-flex justify-content-between flex-row flex-wrap gap-4 mb-4" *ngIf="!isMobile">
          <!-- slideshow prodotti -->
          <carousel
            [itemsPerSlide]="itemsPerSlide"
            [singleSlideOffset]="false"
            [interval]="0"
            [noWrap]="noWrap"
            [indicatorsByChunk]="true"
            class="carousel-tag">
            <slide *ngFor="let prodotto of order.dettagli | filterProductAss; let index=index" >
              <div
                class="row selection-product product-box"
                [ngClass]="{'selectedBox': index == productSelected}"
                (click)="updateProduct(index, prodotto)">
                <div class="div-img">
                  <img [src]="prodotto.imageUrl" [alt]="prodotto.des" class="w-100"/>
                </div>
                <div class="div-text">
                  <p class="product-name align-left truncate-long-text">
                    {{ prodotto.des | titlecase }}
                  </p>
                  <p class="par_2 font-italic align-left product-sku">
                    {{ prodotto.sid }}
                  </p>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
        <div class="d-flex justify-content-between flex-row flex-wrap gap-2 mb-4" *ngIf="isMobile">
          <div *ngFor="let prodotto of order.dettagli | filterProductAss; let index=index" style="width: 100%">
            <div
              class="row selection-product product-box"
              [ngClass]="{'selectedBox': index == productSelected}"
              (click)="updateProduct(index, prodotto)">
                <img [src]="prodotto.imageUrl" [alt]="prodotto.des" class="img-mob" />
              <div class="div-text">
                <p class="product-name align-left truncate-long-text">
                  {{ prodotto.des | titlecase }}
                </p>
                <p class="par_2 font-italic align-left product-sku">{{ prodotto.sid }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-primary"
            (click)="nextStep();"
            [disabled]="productSelected == null">
              {{ "assistance.txtAvanti" | translate | uppercase }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="step == 3">
      <div class="Modal-container logged">
        <h3 class="mb-3 text-center">{{ "assistance.txtAsk" | translate }}</h3>
        <p class="mb-3 text-center subtitleOther">{{ "assistance.txtAssistenzaProdotto" | translate }}</p>
        <p class="mb-4 text-center descriptionOther">{{ "assistance.descAssistenzaProdotto" | translate }}</p>
        <div class="d-flex justify-content-between flex-row flex-wrap gap-4 mb-4">
          <div class="box-container" (click)="updateValueLvlTwo(lvl_two_prodotto_art_danneggiato);" [ngClass]="{'selectedBox': selLvlTwo == 1}">
            <img [src]="assetImg['rotto']" alt="rotto"/>
            <p class="fw-bold text-center">{{ "assistance.txtRotto" | translate }}</p>
          </div>
          <div class="box-container" (click)="updateValueLvlTwo(lvl_two_prodotto_art_mancanti);" [ngClass]="{'selectedBox': selLvlTwo == 2}">
            <img [src]="assetImg['mancante']" alt="mancante"/>
            <p class="fw-bold text-center">{{ "assistance.txtMancante" | translate }}</p>
          </div>
          <div class="box-container" (click)="updateValueLvlTwo(lvl_two_prodotto_art_errato);" [ngClass]="{'selectedBox': selLvlTwo == 3}">
            <img [src]="assetImg['errato']" alt="errato"/>
            <p class="fw-bold text-center">{{ "assistance.txtErrato" | translate }}</p>
          </div>
          <div class="box-container" (click)="updateValueLvlTwo(lvl_two_prodotto_art_difettoso);" [ngClass]="{'selectedBox': selLvlTwo == 4}">
            <img [src]="assetImg['difettoso']" alt="difettoso"/>
            <p class="fw-bold text-center">{{ "assistance.txtDifettoso" | translate }}</p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="step == 4">
      <div class="Modal-container logged">
        <h3 class="mb-3 text-center">{{ "assistance.txtAsk" | translate }}</h3>
        <p class="mb-3 text-center subtitleOther">{{ "assistance.txtAssistenzaProdotto" | translate }}</p>

        <p class="mb-4 text-center">{{ "assistance.txtImg" | translate }}
          <span class="ignore-style" *ngIf="selLvlTwo != 1">
          {{ "assistance.txtImgAlternative" | translate }}
          </span>
        </p>
        <div class="d-flex justify-content-center">
          <input id="auto-trigger" type="file" accept="image/*" (change)="selectFile($event)" hidden
            multiple="multiple" />
          <button (click)="clickUpload()" class="btn btn-primary mb-4 loadImage">{{ "assistance.txtButton" |
            translate }}</button>
        </div>
        <div class="d-flex flex-row justify-content-center mb-4 gap-1 overflow-auto w-100"
          *ngIf="loadedImages.length > 0">
          <div *ngFor="let image of loadedImages;let index = index">
            <img [src]="image.content" width="100px" alt="preview"/>
            <span  (click)="removeSelectedFile(index)" style="position: relative;">
              <i class="bi bi-x-circle remove-image"></i>
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-4">
          <span class="text-center w-50" style="font-size: 12px;">
            {{ "assistance.txtLoadImage" | translate }}</span>
        </div>
        <div class="d-flex justify-content-center">
          <button [disabled]="selLvlTwo == 1 && loadedImages.length === 0" class="btn btn-primary" (click)="sendRequest();">{{
            "assistance.txtSend" | translate }}</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="step == 5">
      <div class="Modal-container logged">
        <h3 class="mb-3 text-center">{{ "assistance.txtAsk" | translate }}</h3>
        <container-element [ngSwitch]="selLvlOne">
          <div *ngSwitchCase="lvl_one_ass_ordine">
            <p class="mb-3 text-center subtitleOther">{{ "assistance.txtAssistenzaOrdine" | translate }}</p>
            <p class="mb-4 text-center descriptionOther">{{ "assistance.descAssistenzaOrdine" | translate }}</p>
          </div>
          <div *ngSwitchCase="lvl_one_ass_consegna">
            <p class="mb-3 text-center subtitleOther">{{ "assistance.txtAssistenzaConsegna" | translate }}</p>
            <p class="mb-4 text-center descriptionOther">{{ "assistance.descAssistenzaConsegna" | translate }}</p>
          </div>
          <div *ngSwitchCase="lvl_one_ass_altro">
            <p class="mb-3 text-center subtitleOther">{{ "assistance.txtAssistenzaAltro" | translate }}</p>
            <p class="mb-4 text-center descriptionOther">{{ "assistance.descAssistenzaAltro" | translate }}</p>
          </div>
          <!-- <div *ngSwitchDefault>...</div> -->
       </container-element>

        <div class="d-flex justify-content-center">
          <button
            class="btn btn-danger disable-hover"
            (click)="goToChat()">
            <img class="img-chat" [src]="assetImg['chat']" alt="chat"/>
            {{ "assistance.chat" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</app-custom-modal>

<app-custom-modal *ngIf="showModalAssistanceDone" (closeModal)="closeModal()" modalClass="assistance">
  <ng-container>
    <div class="Modal-container">
      <div class="assistanceDone">
        <img 
          class="d-flex justify-content-center" 
          style="margin: 0 auto 10px;" 
          [src]="assetImg['success']" 
          alt="success"/>
        <p class="confirm mb-3">{{ "assistance.confirm" | translate }}</p>
        <p class="text-center mb-3">{{ "assistance.confirmTxt" | translate }}</p>
        <span class="back" (click)="goToOrders(); closeModal();">{{ "reservation.back" | translate }}</span>
      </div>
    </div>
  </ng-container>
</app-custom-modal>
