<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()" >
    <div class="Modal-content" id="modal-trasporto-montaggio">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <p class="txt-title">
          {{ "services.modal.moreInfo.txtTitle" | translate | uppercase }}
        </p>
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <div id="more-info-modal" class="row pb-sm-0">
          <div class="row">
            <img 
              [src]="assetImg['icon']" 
              class="mx-auto d-block icon-deleted pb-4" 
              alt="Icon" />
          </div>
          <p *ngIf="!isMobile" class="mb-3 text-center txt-title">{{ "services.modal.moreInfo.txtTitle" | translate | uppercase }}</p>
          <p class="mb-4 text-center">{{ "services.modal.moreInfo.txtSubtitle" | translate }}</p>
          <div class="container">
            <div class="row g-4">
              <!-- Servizi Telefonici -->
              <div class="col-md-3 col-sm-6">
                <a 
                      href="tel:+393896583091">
                  <div class="card contact-card cursor-enable">
                    <img [src]="assetImg['phone']" alt="Servizi Telefonici">
                    <div class="card-body">
                      <p class="card-title fw-bold">
                        {{ "services.modal.moreInfo.txtService" | translate }}
                      </p>
                      
                        <p class="card-text">{{ "services.modal.moreInfo.txtServicePhone" | translate }} 
                          <img 
                          [src]="assetImg['arrow']"
                          class="mx-2 d-block icon-arrow" 
                          alt="Icon" />
                        </p>
                    </div>
                  </div>
                </a>
              </div>
              <!-- Live Chat -->
              <div class="col-md-3 col-sm-6">
                <div class="card contact-card cursor-enable" (click)="goToChat()">
                  <img [src]="assetImg['chat']" alt="Live Chat">
                  <div class="card-body">
                    <p class="card-title fw-bold">
                      {{ "services.modal.moreInfo.txtChat" | translate }}
                    </p>
                    <p class="card-text cursor-enable" >
                      {{ "services.modal.moreInfo.txtServiceChat" | translate }}
                      <img 
                      [src]="assetImg['arrow']"
                      class="mx-2 d-block icon-arrow" 
                      alt="Icon" />
                    </p>
                  </div>
                </div>
              </div>
              <!-- WhatsApp -->
              <div class="col-md-3 col-sm-6">
                <div class="card contact-card cursor-enable" (click)="goToWa()">
                  <img [src]="assetImg['wa']" alt="WhatsApp">
                  <div class="card-body">
                    <p class="card-title fw-bold">
                      {{ "assistance.wa" | translate }}
                    </p>
                    <p class="card-text cursor-enable" >
                      {{ "services.modal.moreInfo.txtServiceWa" | translate }} 
                      <img 
                      [src]="assetImg['arrow']"
                      class="mx-2 d-block icon-arrow" 
                      alt="Icon" />
                    </p>
                  </div>
                </div>
              </div>
              <!-- Punto Vendita -->
              <div class="col-md-3 col-sm-6">
                <div class="card contact-card cursor-enable" (click)="goToReservation()">
                  <img [src]="assetImg['store']" alt="Punto Vendita">
                  <div class="card-body">
                    <p class="card-title fw-bold">
                      {{ "commons.txtAddressStore" | translate }}
                    </p>
                    <p class="card-text cursor-enable">
                      {{ "services.modal.moreInfo.txtServiceStore" | translate }}
                      <img 
                      [src]="assetImg['arrow']"
                      class="mx-2 d-block icon-arrow" 
                      alt="Icon" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>